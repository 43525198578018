import { Navigate, useRoutes } from 'react-router-dom';
// layouts
import DashboardLayout from '../layouts/dashboard';
import SimpleLayout from '../layouts/simple';
//
import LoginPage from '../pages/LoginPage';
import Page404 from '../pages/Page404';
import DashboardAppPage from '../pages/DashboardAppPage';
import EmployeePage from '../pages/EmployeePage';
import AttendancePage from 'src/pages/AttendancePage';
import EmployeeAttendancePage from 'src/pages/EmployeeAttendancePage';
import TaskPage from 'src/pages/TaskPage';
import InquiriesPage from 'src/pages/InquiriesPage';
import JobsPage from 'src/pages/JobsPage';
import TaskDetails from 'src/pages/TaskDetailsPage';
import JobDetails from 'src/pages/JobDetailsPage';
import ApplicationPage from 'src/pages/ApplicationPage';
// ----------------------------------------------------------------------

export default function EmployeeRouter() {
  const routes = useRoutes([
    {
      path: '/dashboard',
      element: <DashboardLayout />,
      children: [
        { element: <Navigate to="/dashboard/app" />, index: true },
        { path: 'app', element: <DashboardAppPage /> },
        { path: 'task/:id', element: <TaskDetails /> },

        










      ],
    },
    {
      element: <SimpleLayout />,
      children: [
        { element: <Navigate to="/dashboard/app" />, index: true },
        { path: '404', element: <Page404 /> },
        { path: '*', element: <Navigate to="/404" /> },
      ],
    },
    {
      path: '*',
      element: <Navigate to="/404" replace />,
    },
  ]);

  return routes;
}
