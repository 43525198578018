import { Helmet } from 'react-helmet-async';
import { useEffect, useState } from 'react';
// @mui
import {
  Card,
  Table,
  Stack,
  TableRow,
  TableBody,
  TableCell,
  Container,
  Typography,
  TableContainer,
  TablePagination,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  TextField,
  DialogTitle,
  Select,
  MenuItem,
  Box,
  InputLabel,
  FormControl,
  InputAdornment,
  IconButton,
  OutlinedInput,
} from '@mui/material';
import FormHelperText from "@mui/material/FormHelperText";
import { axiosApi } from 'src/service/apiEnviornment';
import Button from '@mui/material/Button';
import PencilIcon from '../assets/edit.png';
import DeleteIcon from '../assets/trash.png';
import Iconify from 'src/components/iconify/Iconify';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useForm, Controller } from "react-hook-form";
import moment from 'moment';
// components

import Scrollbar from '../components/scrollbar';
// sections
import { UserListHead } from '../sections/@dashboard/user';
import { useNavigate } from 'react-router-dom';
import Visibility from "mdi-material-ui/Eye";
import VisibilityOff from "mdi-material-ui/EyeOff";
// mock

// ----------------------------------------------------------------------

const TABLE_HEAD = [
  { id: 'name', label: 'Name', alignRight: false },
  { id: 'email', label: 'email', alignRight: false },
  { id: 'phone', label: 'Phone', alignRight: false },
  { id: 'action', label: 'Action', alignRight: false },
];

// ----------------------------------------------------------------------

export default function EmployeePage() {
  const [info, setInfo] = useState({
    name: '',
    email: '',
    phoneNumber: '',
    address: '',
    license: '',
    password: '',
    gender: '',
    dob: '',
    role: '',
    maritalStatus: '',
  });
  const {
    handleSubmit,
    control,
    setError,
    formState: { errors },
    reset
  } = useForm({
    mode: "onChange",
  });
  const navigate = useNavigate();
  const [showPassword, setShowPassword] = useState(false);
  const [password,setPassword]=useState()
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(20);
  const [data, setData] = useState([]);
  const [loader, setLoader] = useState(false);
  const [edit, setEdit] = useState(false);
  const [tokens, setTokens] = useState(0);
  const [isDisabeld, setIsDisabeld] = useState(false);
  const [add, setAdd] = useState(false);
  const inputhandler = (event) => {
    const name = event.target.name;
    const value = event.target.value;
    if (name == 'dob') {
      moment(value).format('YYYY-MM-DD');
    }
    setInfo({ ...info, [name]: value });
  };
  const handleFailure = (msg) => {
    toast.error(msg, {
      position: toast.POSITION.TOP_RIGHT,
      autoClose: 3000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
  };
  const handleSuccess = (msg) => {
    toast.success(msg, {
      position: toast.POSITION.TOP_RIGHT,
      autoClose: 3000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
  };
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };
  const handleClose = () => {
    setPassword("")
    setEdit(false);
    setAdd(false);
    setInfo({
      name: '',
      email: '',
      phoneNumber: '',
      address: '',
      license: '',
      password: '',
      gender: '',
      dob: '',
      role: '',
      maritalStatus: '',
    });
  };
  const [deleteModal, setDeleteModal] = useState(false);

  const handleCloseDelete = () => {
    setDeleteModal(false);
  };

  const handleChangeRowsPerPage = (event) => {
    setPage(0);
    setRowsPerPage(parseInt(event.target.value, 10));
  };
  const getData = async () => {
    setLoader(true);
    await axiosApi('GET', `/employee/get-all-employee`)
      .then((res) => {
        console.log(res.data);
        setData(res.data);
      })
      .catch((err) => {
        console.log(err);
      });
  
    setLoader(false);
  };
  useEffect(() => {
    getData();
  }, []);
  const updateData = async () => {
    setIsDisabeld(true);
    if(password){
      await axiosApi('PUT', `/employee/update-employee-password/${info._id}`, {password})
      .then((res) => {
        console.log(res);
        if (res.status == 409 || res.status == 404 || res.status == 400) {
          handleFailure(res.message);
        } 
      })
      .catch((err) => {
        console.log(err);
      });
      setPassword(false)
    }
    await axiosApi('PUT', `/employee/update-employee/${info._id}`, info)
      .then((res) => {
        console.log(res);
        if (res.status == 409) {
          handleFailure(res.message);
        } else if (res.status == 404) {
          handleFailure(res.message);
        } else if (res.status == 400) {
          handleFailure(res.message);
        } else {
          handleSuccess('Employee updated successfully');
          getData();
        }
      })
      .catch((err) => {
        console.log(err);
      });
    handleClose();
    setIsDisabeld(false);
  };
  const addData = async () => {
    setIsDisabeld(true);
    await axiosApi('POST', `/employee/add-employee`, {...info,password})
      .then((res) => {
        console.log(res);
        if (res.status == 409) {
          handleFailure(res.message);
        } else if (res.status == 404) {
          handleFailure(res.message);
        } else if (res.status == 400) {
          handleFailure(res.message);
        } else {
          handleSuccess('Employee added successfully');
          getData();
          setPassword("")
        }
      })
      .catch((err) => {
        console.log(err);
      });
    handleClose();
    setIsDisabeld(false);
  };
  const del = async () => {
    await axiosApi('DELETE', `/employee/delete-employee-by-id/${deleteModal}`)
      .then((res) => {
        handleSuccess('Employee deleted successfully');
        getData();
      })
      .catch((err) => {
        console.log(err);
      });
    handleCloseDelete();
  };
  return (
    <>
      <Helmet>
        <title> Employee </title>
      </Helmet>

      <Container>
        <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
          <Typography sx={{ color: '#D9B455' }} variant="h4" gutterBottom>
            Employee
          </Typography>
          <Button
          style={{backgroundColor:"#D9B455"}}
            onClick={() => {
              setAdd(true);
            }}
            variant="contained"
            startIcon={<Iconify icon="eva:plus-fill" />}
          >
            New Employee
          </Button>
        </Stack>
        {loader ? (
          <div style={{ height: '200px', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
            <CircularProgress style={{color:"#D9B455"}}/>
          </div>
        ) : (
          <Card>
            <Scrollbar>
              <TableContainer sx={{ minWidth: 800 }}>
                <Table>
                  <UserListHead headLabel={TABLE_HEAD} rowCount={data?.length} />

                  <TableBody>
                    {data?.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)?.map((row, index) => {
                      const { name, email, phoneNumber } = row;

                      return (
                        <TableRow key={index}>
                          <TableCell align="left">{name}</TableCell>
                          <TableCell align="left">{email}</TableCell>
                          <TableCell align="left">{phoneNumber}</TableCell>

                          <TableCell style={{ display: 'flex'}} >
                            <img
                              style={{ cursor: 'pointer', marginLeft: '15px' }}
                              src={PencilIcon}
                              onClick={() => {
                                setInfo(row);
                                setEdit(true);
                              }}
                            ></img>
                            <img
                              style={{ cursor: 'pointer', marginLeft: '15px' }}
                              src={DeleteIcon}
                              onClick={() => {
                                setDeleteModal(row._id);
                              }}
                            ></img>
                          </TableCell>
                        </TableRow>
                      );
                    })}
                  </TableBody>
                </Table>
              </TableContainer>
            </Scrollbar>

            <TablePagination
              rowsPerPageOptions={[20, 50, 100]}
              component="div"
              count={data?.length}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
          </Card>
        )}
        <Dialog
          open={edit || add}
          onClose={handleClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle  style={{color:"#D9B455"}} id="alert-dialog-title">{add ? 'New Employee' : 'Update Employee'}</DialogTitle>
          <DialogContent style={{ minWidth: '600px' }}>
            {edit ? (
              <div style={{ display: 'flex', width: '100%', justifyContent: 'center', alignItems: 'center' }}>
                <h3>Employee Id&nbsp; : &nbsp;</h3>
                <p>{info._id}</p>
              </div>
            ) : null}

            <div>
              <h4> Name</h4>
              <TextField onChange={inputhandler} value={info.name} name="name" style={{ width: '100%' }}></TextField>
            </div>
            {!edit ? (
              <div>
                <h3> Email</h3>
                <TextField
                  type="email"
                  onChange={inputhandler}
                  value={info.email}
                  name="email"
                  style={{ width: '100%' }}
                ></TextField>
              </div>
            ) : null}

            <div>
              <h3> Phone</h3>
              <TextField
                type="number"
                onChange={inputhandler}
                value={info.phoneNumber}
                name="phoneNumber"
                style={{ width: '100%' }}
              ></TextField>
            </div>
            <div>
              <h3> License</h3>
              <Select 
              // sx={{
              //   "& .MuiSelect-nativeInput": {
              //     border: "10px solid #484850",
              //     borderRadius: "5px 5px 0 0"
              //   }
              // }}
              onChange={inputhandler} value={info.license} name="license" style={{ width: '100%' }}>
                <MenuItem value="true">True</MenuItem>
                <MenuItem value="false">False</MenuItem>
              </Select>
            </div>
            <div>
              <h3> D.O.B</h3>
              <TextField
                placeholder={info.dob}
                type="date"
                onChange={inputhandler}
                value={moment(new Date(info.dob)).format('YYYY-MM-DD')}
                name="dob"
                style={{ width: '100%' }}
              ></TextField>
            </div>
            <div>
              <h3> Gender</h3>
              <Select onChange={inputhandler} value={info.gender} name="gender" style={{ width: '100%' }}>
                <MenuItem value="male">Male</MenuItem>
                <MenuItem value="female">Female</MenuItem>
              </Select>
            </div>
            <div>
              <h3> Marital Status</h3>
              <Select onChange={inputhandler} value={info.maritalStatus} name="maritalStatus" style={{ width: '100%' }}>
                <MenuItem value="single">Single</MenuItem>
                <MenuItem value="married">Married</MenuItem>
              </Select>
            </div>
            <div>
              <h3> Role</h3>
              <Select onChange={inputhandler} value={info.role} name="role" style={{ width: '100%' }}>
                <MenuItem value="admin">Admin</MenuItem>
                <MenuItem value="employee">Employee</MenuItem>
              </Select>
            </div>
            <div>
              <h3> Address</h3>
              <TextField
                onChange={inputhandler}
                value={info.address}
                name="address"
                style={{ width: '100%' }}
              ></TextField>
            </div>

              <div>
                <h3> Password</h3>
                {/* <TextField
                  type="password"
                  onChange={inputhandler}
                  value={info.password}
                  name="password"
                  style={{ width: '100%' }}
                ></TextField> */}
                    <Box>
            <FormControl fullWidth>
              <InputLabel 
                   sx={{
                    '& .Mui-focused': {
                      border: 'none !important',
                    }
                  }}
            //  error={Boolean(errors.password)}
             >
                Password
              </InputLabel>
              <Controller
                   sx={{
                    '& .Mui-focused': {
                      border: 'none !important',
                    }
                  }}
                name="password"
                control={control}
                rules={{ required: true }}
                render={({ field: { value, onChange, onBlur } }) => (
                  <OutlinedInput
                  sx={{
                    '& .Mui-focused': {
                      border: 'none !important',
                    }
                  }}
                    onBlur={onBlur}
                    value={password}
                    label={"Password"}
                    name="password"
                    onChange={(e)=>setPassword(e.target.value)}
                    type={showPassword ? "text" : "password"}
                    endAdornment={
                      <InputAdornment 
                      sx={{
                        '& .Mui-focused': {
                          border: 'none !important',
                        }
                      }}
                      position="end">
                        
                        <IconButton
                          onClick={() => setShowPassword(!showPassword)}
                        >
                          {showPassword ? <Visibility /> : <VisibilityOff />}
                        </IconButton>
                      </InputAdornment>
                    }
                  />
                )}
              />



              
              {/* {errors.password && (
                <FormHelperText sx={{ color: "error.main" }}>
                  {errors.password.message}
                </FormHelperText>
              )} */}
            </FormControl>
          </Box>
              </div>
          </DialogContent>
          <DialogActions>
            {add ? (
              <Button           style={{color:"#D9B455"}}
              disabled={isDisabeld} onClick={() => addData()}>
                Add
              </Button>
            ) : (
              <Button  style={{color:"#D9B455"}} disabled={isDisabeld} onClick={() => updateData()}>
                Update
              </Button>
            )}
          </DialogActions>
        </Dialog>

        <Dialog
          open={deleteModal}
          onClose={handleCloseDelete}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle style={{color:"#D9B455"}} id="alert-dialog-title">{'Delete'}</DialogTitle>
          <DialogContent style={{ minWidth: '600px' }}>Are you sure you want to delete?</DialogContent>
          <DialogActions>
            <Button
            style={{color:"#D9B455"}}
              onClick={() => {
                del();
              }}
            >
              Yes
            </Button>
            <Button
            style={{color:"#D9B455"}}
              onClick={() => {
                handleCloseDelete();
              }}
            >
              No
            </Button>
          </DialogActions>
        </Dialog>
      </Container>

      <ToastContainer />
    </>
  );
}
