// component
import SvgColor from '../../../components/svg-color';

// ----------------------------------------------------------------------

const icon = (name) => <SvgColor src={`/assets/icons/navbar/${name}.png`} sx={{ width: 1, height: 1 }} />;

const navConfig = [
  {
    title: 'dashboard',
    path: '/dashboard/app',
    icon: icon('analytics'),
  },
  {
    title: 'Calendar',
    path: '/dashboard/scheduler',
    icon: icon('calendar'),
  },
 
  // {
  //   title: 'Time Tracking',
  //   path: '/dashboard/attendance',
  //   icon: icon('attendance'),
  // },
  {
    title: 'Time Tracking',
    path: '/dashboard/employee-tracking',
    icon: icon('stopwatch'),
  },
  {
    title: 'Scheduler',
    path: '/dashboard/task',
    icon: icon('task'),
  },
  {
    title: 'New Leads',
    path: '/dashboard/inquiries',
    icon: icon('inquiry'),
  }, 
  {
    title: 'jobs',
    path: '/dashboard/jobs',
    icon: icon('jobs'),
  },
  {
    title: 'email',
    path: '/dashboard/email',
    icon: icon('email'),
  },
  {
    title: 'Guide',
    path: '/dashboard/pricing',
    icon: icon('pricing'),
  },
  {
    title: 'employee',
    path: '/dashboard/employee',
    icon: icon('user'),
  },
  {
    title: 'applications',
    path: '/dashboard/applications',
    icon: icon('jobs'),
  },
 
  
  
];

export default navConfig;
