import { Helmet } from 'react-helmet-async';
import { faker } from '@faker-js/faker';
// @mui
import { useTheme } from '@mui/material/styles';
import { Grid, Container, Typography, Stack, Button, CircularProgress } from '@mui/material';
// components
import Iconify from '../components/iconify';
// sections
import {
  AppTasks,
  AppNewsUpdate,
  AppOrderTimeline,
  AppCurrentVisits,
  AppWebsiteVisits,
  AppTrafficBySite,
  AppWidgetSummary,
  AppCurrentSubject,
  AppConversionRates,
} from '../sections/@dashboard/app';
import { useAuth } from 'src/hooks/useAuth';
import { axiosApi } from 'src/service/apiEnviornment';
import { useState, useEffect } from 'react';
import moment from 'moment';
import { useNavigate } from 'react-router';

// ----------------------------------------------------------------------

export default function DashboardAppPage() {
  const theme = useTheme();
  const { user } = useAuth()
  const [data, setData] = useState([])
  const [tasks, setTasks] = useState([])
  const [hear, setHear] = useState([])
  const navigate = useNavigate()
  const [dashboard, setDashboard] = useState(false)
  const [employee, setEmployee] = useState(false)
  const [employeeTop, setTopEmployee] = useState([])

  const [loader, setLoader] = useState(false);

  const getData = async () => {
    // setLoader(true)
    await axiosApi("GET", `/attendance/check-attendance`)
      .then((res) => {
        console.log(res.data)

        setData(res.data);
      })
      .catch((err) => {
        console.log(err);
      });
    // setLoader(false)

  };
  const getTasks = async () => {
    // setLoader(true)
    await axiosApi("GET", `/task/get-task-by-employee`)
      .then((res) => {
        console.log(res.data)
        setTasks(res.data);
      })
      .catch((err) => {
        console.log(err);
      });
    // setLoader(false)

  };
  const getDashboardCount = async () => {
    // setLoader(true)
    await axiosApi("GET", `/inquiry/get-dashboard-count`)
      .then((res) => {
        console.log(res.data)
        setDashboard(res.data);
      })
      .catch((err) => {
        console.log(err);
      });
    // setLoader(false)

  };
  const getHearCount = async () => {
    // setLoader(true)
    await axiosApi("GET", `/inquiry/get-hear-count`)
      .then((res) => {
        console.log(res.data)
        setHear(res.data);
      })
      .catch((err) => {
        console.log(err);
      });
    // setLoader(false)

  };
  const getEmployeeCount = async () => {
    // setLoader(true)
    await axiosApi("GET", `/employee/get-employee-count`)
      .then((res) => {
        console.log(res.data)
        setEmployee(res.data);
      })
      .catch((err) => {
        console.log(err);
      });
    // setLoader(false)

  };
  const getTop10Employees = async () => {
    // setLoader(true)
    await axiosApi("GET", `/tracking/top-10-employees`)
      .then((res) => {
        console.log(res.data)
        setTopEmployee(res.data);
      })
      .catch((err) => {
        console.log(err);
      });
    // setLoader(false)

  };
  useEffect(() => {
    setLoader(true)
    getData()
    getTasks()
    getDashboardCount()
    getEmployeeCount()
    getTop10Employees()
    getHearCount()
    setLoader(false)

  }, [])

  console.log("dashboard", employeeTop)
  return (
    <>
      <Helmet>
        <title> Sparkle and Shine Cleaning Service </title>
      </Helmet>

      <Container maxWidth="xl">

        <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
          <Typography variant="h4" sx={{ mb: 5, color: '#D9B455' }}>
            Hi, Welcome back {user?.name}
          </Typography>


        </Stack>
        {user?.role == "admin" ?
          !loader ?
            <Grid container spacing={3}>
              <Grid item xs={12} sm={6} md={3}>
                <AppWidgetSummary title="Total Inquiries" total={dashboard[0]?.totalDocuments ? dashboard[0]?.totalDocuments : 0} />
              </Grid>

              <Grid item xs={12} sm={6} md={3}>
                <AppWidgetSummary title="Total Sale" total={dashboard[0]?.saleDocuments ? dashboard[0]?.saleDocuments : 0} color="info" />
              </Grid>

              <Grid item xs={12} sm={6} md={3}>
                <AppWidgetSummary title="Not Sale" total={dashboard[0]?.totalDocuments ? (dashboard[0]?.totalDocuments - dashboard[0]?.saleDocuments) : 0} color="warning" />
              </Grid>

              <Grid item xs={12} sm={6} md={3}>
                <AppWidgetSummary title="Employees" total={employee} color="error" />
              </Grid>



              <Grid item xs={12} md={6} lg={4}>
                <AppCurrentVisits
                  title="Marketing Analysis"
                  chartData={

                    hear?.map((item) => {
                      return (
                        { label: item._id, value: item.count }

                      )
                    })
                  }

                />
              </Grid>

              <Grid item xs={12} md={6} lg={8}>
                <AppConversionRates
                  title="Top 10 Employees"
                  subheader="Most no of hours"
                  chartData={
                    employeeTop?.map((item, index) => {
                      return { label: item?.employeeInfo?.name, value: item?.totalHours }
                    })


                  }
                />
              </Grid>

            </Grid>
            : <CircularProgress />
          :
          <Grid container spacing={3}>
            {tasks?.map((item, index) => {
              return (
                <Grid xs={12} sm={12} md={12} style={{ border: "2px #D9B455 solid", borderRadius: "5px", padding: "20px", marginTop: "20px" }}>
                  <div>
                    <h3>Scheduler Description</h3>
                    <p>{item?.description}</p>
                  </div>
                  <div>
                    <h3>Job Description</h3>
                    <p>{item?.populatedJobIds[0].message}</p>
                  </div>
                  <div>
                    <h3>Date & Time</h3>
                    <p>{moment(item?.populatedJobIds[0].date).format("YYYY-MM-DD")} {item?.populatedJobIds[0].time}</p>
                  </div>
                  <div style={{ display: "flex", justifyContent: "space-around" }}>
                    <h3 style={{ cursor: 'pointer', color: "#D9B455" }} onClick={() => { navigate(`/dashboard/task/${item._id}`) }} >Start</h3>
                  </div>

                </Grid>
              )
            })}
          </Grid>
        }

      </Container>
    </>
  );
}
