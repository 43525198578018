import { Helmet } from 'react-helmet-async';
import { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
// @mui
import {
    Card,
    Table,
    Stack,
    TableRow,
    TableBody,
    TableCell,
    Container,
    Typography,
    TableContainer,
    TablePagination,
    CircularProgress,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    TextField,
    Select,
    MenuItem
} from '@mui/material';
import { axiosApi } from 'src/service/apiEnviornment';
import Button from '@mui/material/Button';
import EyeIcon from "../assets/eye.png"
import DeleteIcon from "../assets/trash.png"
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
// components

import Scrollbar from '../components/scrollbar';
// sections
import { UserListHead } from '../sections/@dashboard/user';
// mock
import moment from "moment"
import { useNavigate } from 'react-router';
// ----------------------------------------------------------------------

const TABLE_HEAD = [
    { id: 'firstName', label: 'First Name', alignRight: false },
    { id: 'lastName', label: 'Last Name', alignRight: false },
    { id: 'email', label: 'Email', alignRight: false },
    { id: 'hearAboutUs', label: 'Hear About Us', alignRight: false },
    { id: 'action', label: 'Action', alignRight: false },
];

// ----------------------------------------------------------------------




export default function PricingPage() {
    const navigate = useNavigate()
    const [info, setInfo] = useState({
        firstName: "",
        lastName: '',
        email:"",
        hearAboutUs:"",

    })
    const [deleteModal, setDeleteModal] = useState(false)
    const [isSaleModal, setIsSaleModal] = useState(false)
    const [isDisabeld, setIsDisabeld] = useState(false)


    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(20);
    const [data, setData] = useState([])
    const [loader, setLoader] = useState(false);
    const [details, setDetails] = useState(false)

    const inputhandler = (event) => {
        const name = event.target.name;
        const value = event.target.value
        setInfo({ ...info, [name]: value })
    }
    const handleCloseIsSale = () => {
        setIsSaleModal(false)
    };
    const handleCloseDelete = () => {
        setDeleteModal(false)
    };

    const handleFailure = (msg) => {
        toast.error(msg, {
            position: toast.POSITION.TOP_RIGHT,
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
        });
    };
    const handleSuccess = (msg) => {
        toast.success(msg, {
            position: toast.POSITION.TOP_RIGHT,
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
        });
    };
    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };
    const handleClose = () => {
        setDetails(false);
        setInfo({
            firstName: "",
            lastName: '',
            hearAboutUs: "",
            message: '',
            expertise: '',
            experience: '',
            daysOfWeek: '',
            hoursPerDay: '',
            license: '',
            car: '',
            resume: '',
        })

    };

    const handleChangeRowsPerPage = (event) => {
        setPage(0);
        setRowsPerPage(parseInt(event.target.value, 10));
    };

    const updateData = async () => {
        setIsDisabeld(true)

        await axiosApi("PUT", `/application/update-application-by-id/${isSaleModal._id}`, { isSale: true, status: "new-job" })
            .then((res) => {
                console.log(res)
                handleSuccess("Application added in jobs")
                getData()
            })
            .catch((err) => {
                handleFailure("Error in adding application")
                console.log(err);
            });
        handleCloseIsSale()
        setIsDisabeld(false)
    }
    const del = async (id) => {
        await axiosApi("DELETE", `/application/delete-application-by-id/${deleteModal}`)
            .then((res) => {
                handleSuccess("Application deleted successfully")
                getData()
            })
            .catch((err) => {
                console.log(err);
                handleFailure("Error in deleting application")

            });
        handleCloseDelete()
    }
    const getData = async () => {
        setLoader(true)
        await axiosApi("GET", `/pricing/get-all-pricing`)
            .then((res) => {
                console.log(res.data)
                setData(res.data);
            })
            .catch((err) => {
                console.log(err);
            });
        setLoader(false)

    };


    useEffect(() => {
        getData()
    }, [])

    return (
        <>
            <Helmet>
                <title>  Guide  </title>
            </Helmet>

            <Container>
                <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
                    <Typography style={{color:"#D9B455"}} variant="h4" gutterBottom>
                     Guide 
                    </Typography>

                </Stack>
                {loader ? <div style={{ height: "200px", display: "flex", justifyContent: "center", alignItems: "center" }}>
                    <CircularProgress />
                </div>
                    :
                    <Card>

                        <Scrollbar>
                            <TableContainer sx={{ minWidth: 800 }}>
                                <Table>
                                    <UserListHead
                                        headLabel={TABLE_HEAD}
                                        rowCount={data?.length}
                                    />

                                    <TableBody>
                                        {data?.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)?.map((row, index) => {
                                            const { firstName, lastName, email,hearAboutUs } = row;

                                            return (
                                                <TableRow key={index}  >
                                                    <TableCell align="left">{firstName}</TableCell>
                                                    <TableCell align="left">{lastName}</TableCell>

                                                    <TableCell align="left">{email}</TableCell>
                                                    <TableCell align="left">{hearAboutUs}</TableCell>
             

                                                    <TableCell style={{ display: "flex" }} align="left">
                                                        <img style={{ cursor: "pointer", marginLeft: "15px" }} src={EyeIcon} onClick={() => { setInfo(row); setDetails(true) }}></img>
                                                    </TableCell>
                                                </TableRow>
                                            );
                                        })}

                                    </TableBody>
                                </Table>
                            </TableContainer>
                        </Scrollbar>

                        <TablePagination
                            rowsPerPageOptions={[20, 50, 100]}
                            component="div"
                            count={data?.length}
                            rowsPerPage={rowsPerPage}
                            page={page}
                            onPageChange={handleChangePage}
                            onRowsPerPageChange={handleChangeRowsPerPage}
                        />
                    </Card>
                }
                <Dialog
                    open={details}
                    onClose={handleClose}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                >
                    <DialogTitle style={{display:"flex",justifyContent:"space-between"}} id="alert-dialog-title">
                        {"Guide Details"}
                        {/* <a href={info.resume} target="_blank">
                        <Button variant='contained'>Resume</Button>
                    </a> */}
                    </DialogTitle>
                    

                    <DialogContent style={{ minWidth: "600px" }}>

                        <div>
                            <h3>Name</h3>
                            <TextField onChange={inputhandler} value={info.firstName + " " + info.lastName} name="name" style={{ width: "100%" }}></TextField>
                        </div>
                        <div>
                            <h3>email</h3>
                            <TextField onChange={inputhandler} value={info.email} name="email" style={{ width: "100%" }}></TextField>
                        </div>
                        <div>
                            <h3>Hear About Us</h3>
                            <TextField onChange={inputhandler} value={info.hearAboutUs} name="hearAboutUs" style={{ width: "100%" }}></TextField>
                        </div>
                        <div>
                            <h3>Message</h3>
                            <TextField multiline={true} rows={5} onChange={inputhandler} value={info.message} name="message" style={{ width: "100%" }}></TextField>
                        </div>
                      


                    </DialogContent>
                    {/* <DialogActions>
            {add ?
              <Button disabled={isDisabeld} onClick={() => addData()}>Add</Button>
              :
              <Button disabled={isDisabeld} onClick={() => updateData()}>Update</Button>
            }

          </DialogActions> */}
                </Dialog>

            </Container>
            <ToastContainer />

        </>
    );
}
