import HomeRouter from './routes/homeRoutes';
import './App.css';
import EmployeeRouter from './routes/employeeRoutes';
import { useAuth } from './hooks/useAuth';
import AuthRouter from './routes/authRoutes';
// ----------------------------------------------------------------------

export default function App() {
  const { user } = useAuth();
  // console.log("check",user)
  return (
    <>
      {/* <div style={{ height: '100vh', width: '100vw', display: 'flex', justifyContent: 'center', alignItems: 'center',flexDirection:'column',overflow:'hidden' }}>
        <h1 style={{ color: 'red',fontSize:50 }}> CRM IS TEMPORARILY DOWN</h1>
        <img src='https://img.freepik.com/free-vector/503-error-service-unavailable-concept-illustration_114360-1906.jpg'></img>
        <h3  style={{ color: 'red',fontSize:20 }}>Please contact admin for further details </h3>
      </div> */}
      {(user?.email) ?((user?.role=="admin")? <HomeRouter />:<EmployeeRouter/>) : <AuthRouter/>}
    </>
  );
}