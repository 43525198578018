import { Helmet } from 'react-helmet-async';
import { useEffect, useState, useCallback } from 'react';
// @mui
import {
    Card,
    Table,
    Stack,
    TableRow,
    TableBody,
    TableCell,
    Container,
    Typography,
    TableContainer,
    TablePagination,
    CircularProgress,
    Dialog,
    DialogActions,
    DialogContent,
    TextField,
    DialogTitle,
    Stepper,
    Step,
    StepLabel,
    InputLabel,
    Select,
    MenuItem,
    Box
} from '@mui/material';
import { axiosApi } from 'src/service/apiEnviornment';
import Button from '@mui/material/Button';
import EyeIcon from "../assets/eye.png"
import DeleteIcon from "../assets/trash.png"
import Iconify from 'src/components/iconify/Iconify';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { GoogleMap, useJsApiLoader, Marker, DirectionsRenderer } from "@react-google-maps/api";
import image from "../assets/pin.png"
// import truck from "../assets/fire_truck.png"


import moment from "moment"
// components

import Scrollbar from '../components/scrollbar';
// sections
import { UserListHead } from '../sections/@dashboard/user';
// mock
import { useParams } from 'react-router-dom';
import PinLocation from 'src/components/maps/PinLocation';
// ----------------------------------------------------------------------

const TABLE_HEAD = [
    { id: 'reqForEmergency', label: 'Emergency Occur', alignRight: false },
    // { id: 'lastName', label: 'Last Name', alignRight: false },

    { id: 'action', label: 'Action', alignRight: false },
];

// ----------------------------------------------------------------------

const steps = [
    'New Job',
    'Assignment',
    'In Progress',
    'Completed',
];


export default function JobDetails() {
    const { id } = useParams()




    const [info, setInfo] = useState({
        firstName: '',
        lastName: '',
        cnic: '',
        phone: '',
        password: '',
        email: '',
        role: 'Driver'
    })

    const [data, setData] = useState([])
    const [loader, setLoader] = useState(false);
    const [isDisabeld, setIsDisabeld] = useState(false)
    const [add, setAdd] = useState(false)
    const [activeStepNo, setActiveStepNo] = useState(1)
    const [drivers, setDrivers] = useState()
    const [trucks, setTrucks] = useState()
    const [driverId, setDriverId] = useState()
    const [truckId, setTruckId] = useState()
    const inputhandler = (event) => {
        const name = event.target.name;
        const value = event.target.value
        setInfo({ ...info, [name]: value })
    }
    const handleFailure = (msg) => {
        toast.error(msg, {
            position: toast.POSITION.TOP_RIGHT,
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
        });
    };
    const handleSuccess = (msg) => {
        toast.success(msg, {
            position: toast.POSITION.TOP_RIGHT,
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
        });
    };

    const handleClose = () => {
        setAdd(false);

    };


    function checkStep(res) {
        if (res.status == "completed") {
            setActiveStepNo(4)
        }
        else if (res.status == "inprogress") {
            setActiveStepNo(3)
        }
        else if (res.status == "assignment") {
            setActiveStepNo(2)
        }
        else {
            setActiveStepNo(1)
        }

    }
    const getData = async () => {
        await axiosApi("GET", `/inquiry/get-inquiry-by-id/${id}`)
            .then((res) => {
                checkStep(res.data)
                setData(res.data);
            })
            .catch((err) => {
                console.log(err);
            });

    };

    useEffect(() => {
        setLoader(true)
        getData()
        setLoader(false)
    }, [])

    const updateData = async () => {
        setIsDisabeld(true)
        const body = {
            truckId,
            driverId,
            emergencyId: id
        }
        console.log(body)
        const checkAvailability = await axiosApi("GET", `/emergency/check-driver-availability/${body.driverId}`)
        console.log(checkAvailability)
        if (checkAvailability.message.length > 0) {
            handleFailure("Driver is busy in other emergency")

        } else {
            if (data.driverId.length > 0) {
                await axiosApi("PUT", `/emergency/add-truck-driver`, body)
                    .then((res) => {
                        console.log(res)
                        if (res.status == 200) {
                            handleSuccess("Driver Added successfully")
                            getData()
                        }
                        else if (res.status == 409) {
                            handleFailure(res.message)
                        }
                        else {
                            handleFailure("Error Occurred")
                        }
                    })
                    .catch((err) => {
                        console.log(err);
                    });
            }
            else {
                await axiosApi("PUT", `/emergency/first-truck-driver`, body)
                    .then((res) => {
                        console.log(res)
                        if (res.status == 200) {
                            handleSuccess("Driver Added successfully")
                            getData()
                        }
                        else if (res.status == 409) {
                            handleFailure(res.message)
                        }
                        else {
                            handleFailure("Error Occurred")
                        }
                    })
                    .catch((err) => {
                        console.log(err);
                    });
            }
        }


        handleClose()
        setIsDisabeld(false)

    }
    console.log("data", data)

    return (
        <>
            <Helmet>
                <title> Job Tracking  </title>
            </Helmet>

            <Container>
                <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
                    <Typography style={{ color: "#D9B455" }} variant="h4" gutterBottom>
                        Job Tracking
                    </Typography>

                </Stack>
                {!loader ?

                    <Box sx={{ width: '100%' }}>
                        <Stepper activeStep={activeStepNo} alternativeLabel>
                            {steps?.map((label) => (
                                <Step sx={{
                                    '& .MuiStepLabel-root .Mui-completed': {
                                        color: '#D9B455', // circle color (COMPLETED)
                                    }
                                    ,
                                    '& .MuiStepLabel-root .Mui-active': {
                                        color: '#D9B455', // circle color (ACTIVE)
                                    },
                                }}
                                 key={label}>
                                    <StepLabel>{label}</StepLabel>
                                </Step>
                            ))}
                        </Stepper>

                    </Box>

                    : <CircularProgress style={{ color: "#D9B455" }} />
                }


            </Container>



            <Container>
                <Stack direction="row" alignItems="center" justifyContent="space-between" mt={2} mb={2}>
                    <Typography variant="h4" gutterBottom>
                        Customer Details
                    </Typography>

                </Stack>
                {!loader ?


                    <Box style={{ border: "2px #D9B455 solid", borderRadius: "10px", padding: "0px 20px", marginBottom: "10px" }}>
                        <div style={{ display: "flex", width: "100%" }}>
                            <p style={{ width: "50%" }}>Name</p>
                            <p style={{ width: "50%" }}>{data?.name}</p>
                        </div>
                        <div style={{ display: "flex", width: "100%" }}>
                            <p style={{ width: "50%" }}>Phone</p>
                            <p style={{ width: "50%" }}>{data?.phoneNumber}</p>
                        </div>
                        <div style={{ display: "flex", width: "100%" }}>
                            <p style={{ width: "50%" }}>Email</p>
                            <p style={{ width: "50%" }}>{data?.email}</p>
                        </div>
                        <div style={{ display: "flex", width: "100%" }}>
                            <p style={{ width: "50%" }}>Type</p>
                            <p style={{ width: "50%" }}>{data?.type}</p>
                        </div>
                        <div style={{ display: "flex", width: "100%" }}>
                            <p style={{ width: "50%" }}>Message</p>
                            <p style={{ width: "50%" }}>{data?.message}</p>
                        </div>


                    </Box>

                    : <CircularProgress style={{ color: "#D9B455" }} />
                }


            </Container>


            <ToastContainer />
            <Dialog
                open={add}
                onClose={handleClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">
                    Add Driver
                </DialogTitle>
                <DialogContent style={{ minWidth: "600px" }}>
                    <div>
                        <InputLabel>Drivers</InputLabel>
                        <Select
                            // value={jobId}
                            onChange={(e) => setDriverId(e.target.value)}
                            style={{ width: "100%" }}
                        >
                            {drivers?.filter((item1) => !data?.driverId?.some((item2) => item2._id === item1._id))?.map(item => (
                                <MenuItem key={item._id} value={item._id}>{item.firstName + " " + item.lastName}</MenuItem>
                            ))}
                        </Select>
                    </div>
                    <div>
                        <InputLabel>Trucks</InputLabel>
                        <Select
                            value={truckId}
                            onChange={(e) => setTruckId(e.target.value)}
                            style={{ width: "100%" }}
                        >
                            {trucks?.filter((item1) => !data?.truckId?.some((item2) => item2._id === item1._id))?.map(item => (
                                <MenuItem key={item._id} value={item._id}>{item.make + " - " + item.model}</MenuItem>
                            ))}
                        </Select>
                    </div>
                    <DialogActions>

                        <Button disabled={isDisabeld} onClick={() => updateData()}>Add</Button>


                    </DialogActions>


                </DialogContent>

            </Dialog>
        </>
    );
}
