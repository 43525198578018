import React from 'react';
import moment from 'moment';
import 'react-big-calendar/lib/css/react-big-calendar.css';

const CustomToolbar = (props) => {
  const navigate = (action) => {
    if (action === 'PREV') {
      props.onNavigate('PREV');
    } else if (action === 'TODAY') {
      props.onNavigate('TODAY');
    } else if (action === 'NEXT') {
      props.onNavigate('NEXT');
    }
  };

  const view = (view) => {
    props.onView(view);
  };
  return (
    <div className="rbc-toolbar">
      <div className="rbc-btn-group">
        <button type="button" onClick={() => navigate('PREV')}>
          Back
        </button>
        <button type="button" onClick={() => navigate('TODAY')}>
          Today
        </button>
        <button type="button" onClick={() => navigate('NEXT')}>
          Next
        </button>
      </div>
      <div className="rbc-toolbar-label">{props.label}</div>
      <div className="rbc-btn-group">
        <button type="button" onClick={() => view('month')}>
          Month
        </button>
        <button type="button" onClick={() => view('week')}>
          Week
        </button>
        <button type="button" onClick={() => view('day')}>
          Day
        </button>
      </div>
    </div>
  );
};

export default CustomToolbar;
