import { Helmet } from 'react-helmet-async';
import { useEffect, useState } from 'react';
// @mui
import {
  Card,
  Table,
  Stack,
  TableRow,
  TableBody,
  TableCell,
  Container,
  Typography,
  TableContainer,
  TablePagination,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
  Select,
  MenuItem
} from '@mui/material';
import axios from "axios"
import GooglePlacesAutocomplete from 'react-google-places-autocomplete';
import CloseIcon from "../assets/close.png"


import { axiosApi } from 'src/service/apiEnviornment';
import Button from '@mui/material/Button';
import EyeIcon from "../assets/eye.png"
import DeleteIcon from "../assets/trash.png"
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
// components

import Scrollbar from '../components/scrollbar';
// sections
import { UserListHead } from '../sections/@dashboard/user';
// mock
import moment from "moment"
// ----------------------------------------------------------------------

const TABLE_HEAD = [
  { id: 'name', label: 'Name', alignRight: false },
  { id: 'email', label: 'Email', alignRight: false },
  { id: 'phone', label: 'Phone Number', alignRight: false },
  { id: 'type', label: 'Type', alignRight: false },
  { id: 'sale', label: 'New Client', alignRight: false },
  { id: 'action', label: 'Action', alignRight: false },
];

// ----------------------------------------------------------------------




export default function InquiriesPage() {
  const [info, setInfo] = useState({
    description: "",
    employeeIds: [],
  })
  const [nearestPoint, setNearestPoint] = useState('')
  const [address, setAddress] = useState("")
  const [date, setDate] = useState()
  const [endTime, setEndTime] = useState()
  const [startTime, setStartTime] = useState()
  const [employees, setEmployees] = useState([])


  const [deleteModal, setDeleteModal] = useState(false)
  const [isSaleModal, setIsSaleModal] = useState(false)
  const [isDisabeld, setIsDisabeld] = useState(false)


  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(20);
  const [data, setData] = useState([])
  const [loader, setLoader] = useState(false);
  const [details, setDetails] = useState(false)

  const inputhandler = (event) => {
    const name = event.target.name;
    const value = event.target.value
    setInfo({ ...info, [name]: value })
  }
  const handleCloseIsSale = () => {
    setIsSaleModal(false)
  };
  const handleCloseDelete = () => {
    setDeleteModal(false)
  };

  const handleFailure = (msg) => {
    toast.error(msg, {
      position: toast.POSITION.TOP_RIGHT,
      autoClose: 3000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
  };
  const handleSuccess = (msg) => {
    toast.success(msg, {
      position: toast.POSITION.TOP_RIGHT,
      autoClose: 3000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
  };
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };
  const handleClose = () => {
    setDetails(false);
    setInfo({
      description: "",
      employeeIds: [],

    })

  };

  const handleChangeRowsPerPage = (event) => {
    setPage(0);
    setRowsPerPage(parseInt(event.target.value, 10));
  };

  const updateData = async () => {
    const body = {
      date, endTime, startTime, isSale: true, status: "assignment",
      placeId: nearestPoint.value.place_id,
      address,
      ...info
    }
    console.log(body)
    setIsDisabeld(true)

    await axiosApi("PUT", `/inquiry/update-inquiry-with-task-by-id/${isSaleModal._id}`, body)
      .then((res) => {
        console.log(res)
        handleSuccess("Lead added in jobs")
        getData()
        setStartTime()
        setEndTime()
        setAddress()
        setDate()

      })
      .catch((err) => {
        handleFailure("Error in adding Lead")
        console.log(err);
      });
    handleCloseIsSale()
    handleClose()
    setIsDisabeld(false)
  }
  const del = async (id) => {
    await axiosApi("DELETE", `/inquiry/delete-inquiry-by-id/${deleteModal}`)
      .then((res) => {
        handleSuccess("Lead deleted successfully")
        getData()
      })
      .catch((err) => {
        console.log(err);
        handleFailure("Error in deleting inquiry")

      });
    handleCloseDelete()
  }
  const getData = async () => {
    setLoader(true)
    await axiosApi("GET", `/inquiry/get-all-inquiry`)
      .then((res) => {
        console.log(res.data)
        setData(res.data);
      })
      .catch((err) => {
        console.log(err);
      });
    setLoader(false)

  };
  const getEmployees = async () => {
    setLoader(true)
    await axiosApi("GET", `/employee/get-all-employee`)
      .then((res) => {
        console.log(res.data)
        setEmployees(res.data);
      })
      .catch((err) => {
        console.log(err);
      });
    setLoader(false)

  };
  // console.log("nearestPoint", nearestPoint)

  useEffect(() => {
    getData()
    getEmployees()
  }, [])

  const handlePlaceSelect = (place) => {
    setNearestPoint(place)
  };
  return (
    <>
      <Helmet>
        <title> New Leads  </title>
      </Helmet>

      <Container>
        <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
          <Typography style={{ color: "#D9B455" }} variant="h4" gutterBottom>
          New Leads
          </Typography>

        </Stack>
        {loader ? <div style={{ height: "200px", display: "flex", justifyContent: "center", alignItems: "center" }}>
          <CircularProgress style={{ color: "#D9B455" }} />
        </div>
          :
          <Card>

            <Scrollbar>
              <TableContainer sx={{ minWidth: 800 }}>
                <Table>
                  <UserListHead
                    headLabel={TABLE_HEAD}
                    rowCount={data?.length}
                  />

                  <TableBody>
                    {data?.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)?.map((row, index) => {
                      const { name, email, phoneNumber, type } = row;

                      return (
                        <TableRow key={index}  >
                          <TableCell align="left">{name}</TableCell>
                          <TableCell align="left">{email}</TableCell>
                          <TableCell align="left">{phoneNumber}</TableCell>
                          <TableCell align="left">{type}</TableCell>
                          <TableCell align="left">
                            <Button style={{ backgroundColor: "#D9B455" }} variant='contained' onClick={() => setIsSaleModal(row)}>Yes</Button>

                          </TableCell>



                          <TableCell style={{ display: "flex" }} >
                            <img style={{ cursor: "pointer", marginLeft: "15px" }} src={EyeIcon} onClick={() => { setInfo(row); setDetails(true) }}></img>
                            <img style={{ cursor: "pointer", marginLeft: "15px" }} src={DeleteIcon} onClick={() => { setDeleteModal(row._id) }}></img>

                          </TableCell>
                        </TableRow>
                      );
                    })}

                  </TableBody>
                </Table>
              </TableContainer>
            </Scrollbar>

            <TablePagination
              rowsPerPageOptions={[20, 50, 100]}
              component="div"
              count={data?.length}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
          </Card>
        }
        <Dialog
          open={details}
          onClose={handleClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">
            {"New Lead Details"}
          </DialogTitle>
          <DialogContent style={{ minWidth: "600px" }}>

            <div>
              <h3>Name</h3>
              <TextField onChange={inputhandler} value={info.name} name="name" style={{ width: "100%" }}></TextField>
            </div>
            <div>
              <h3>email</h3>
              <TextField onChange={inputhandler} value={info.email} name="email" style={{ width: "100%" }}></TextField>
            </div>
            <div>
              <h3>Phone Number</h3>
              <TextField onChange={inputhandler} value={info.phoneNumber} name="phoneNumber" style={{ width: "100%" }}></TextField>
            </div>
            <div>
              <h3>Type</h3>
              <TextField onChange={inputhandler} value={info.type} name="type" style={{ width: "100%" }}></TextField>
            </div>
            <div>
              <h3>How hear about us?</h3>
              <TextField onChange={inputhandler} value={info.hearAboutUs} name="hearAboutUs" style={{ width: "100%" }}></TextField>
            </div>
            <div>
              <h3>Message</h3>
              <TextField multiline={true} rows={5} onChange={inputhandler} value={info.message} name="message" style={{ width: "100%" }}></TextField>
            </div>


          </DialogContent>
  
        </Dialog>
        <Dialog
          open={isSaleModal}
          onClose={handleCloseIsSale}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle style={{ color: "#D9B455" }} id="alert-dialog-title">
            {"New Client"}
          </DialogTitle>
          <DialogContent style={{ minWidth: "600px" }}>
            <h4>Address</h4>
            <TextField value={address} onChange={(e) => setAddress(e.target.value)} style={{ width: "100%" }} type="text" />

            <h4>Nearest Point</h4>
            <div >
              <GooglePlacesAutocomplete
                apiKey="AIzaSyAN_6e4jAOJNXNpYDjK5VrOaHLFadULobA"
                autocompletionRequest={{
                }}
                selectProps={{
                  placeholder: 'Nearest Point',
                  name: "nearestPoint",
                  onChange: handlePlaceSelect
                }}
                onLoadFailed={(error) => {
                  console.error('Could not inject Google script', error);
                }}


              />
            </div>
            <h4>Date</h4>
            <TextField value={date} onChange={(e) => setDate(e.target.value)} style={{ width: "100%" }} type="date" />
            <h4> Start Time</h4>

            <TextField value={startTime} onChange={(e) => setStartTime(e.target.value)} style={{ width: "100%" }} type="time" />


            <h4>End Time</h4>

            <TextField value={endTime} onChange={(e) => setEndTime(e.target.value)} style={{ width: "100%" }} type="time" />

            <div>
              <h3> Select Employees</h3>
              <div>
                {info.employeeIds?.map((item, index) => {
                  return (<div style={{ display: "flex", justifyContent: "space-between" }}>
                    <p>{item.name}</p>
                    <div onClick={(e) => { info.employeeIds.splice(index, 1); console.log(index); setInfo({ ...info, employeeIds: [...info.employeeIds] }) }} style={{ cursor: "pointer", display: "flex", justifyContent: "center", alignItems: "center" }}><img src={CloseIcon}></img></div>
                  </div>)
                })}
              </div>
              <Select onChange={(e) => { setInfo({ ...info, employeeIds: [...info.employeeIds, { ...e.target.value, latitude: null, longitude: null }] }) }} name="employeeIds" style={{ width: "100%" }}>
                {employees?.filter((item1) => !info.employeeIds?.some((item2) => item2._id === item1._id))?.map((item) => {
                  return (
                    <MenuItem value={item}>{item.name}</MenuItem>
                  )
                })}
              </Select>
            </div>
            <div>
              <h3>Description</h3>
              <TextField multiline={true} rows={5} onChange={inputhandler} value={info.description} name="description" style={{ width: "100%" }}></TextField>
            </div>
          </DialogContent>
          <DialogActions>
            <Button style={{ color: "#D9B455" }} onClick={() => { updateData() }}>Submit</Button>
            {/* <Button onClick={() => { handleCloseIsSale() }}>No</Button> */}

          </DialogActions>
        </Dialog>
        <Dialog
          open={deleteModal}
          onClose={handleCloseDelete}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle style={{ color: "#D9B455" }} id="alert-dialog-title">
            {"Delete"}
          </DialogTitle>
          <DialogContent style={{ minWidth: "600px" }}>
            Are you sure you want to delete?


          </DialogContent>
          <DialogActions>
            <Button style={{ color: "#D9B455" }} onClick={() => { del() }}>Yes</Button>
            <Button style={{ color: "#D9B455" }} onClick={() => { handleCloseDelete() }}>No</Button>

          </DialogActions>
        </Dialog>
      </Container>
      <ToastContainer />

    </>
  );
}
