import { Helmet } from 'react-helmet-async';
import { useEffect, useState } from 'react';
// @mui
import {
  Card,
  Table,
  Stack,
  TableRow,
  TableBody,
  TableCell,
  Container,
  Typography,
  TableContainer,
  TablePagination,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
  Select,
  MenuItem
} from '@mui/material';
import CloseIcon from "../assets/close.png"

import { axiosApi } from 'src/service/apiEnviornment';
import Button from '@mui/material/Button';
import PencilIcon from "../assets/edit.png"
import DeleteIcon from "../assets/trash.png"
import EyeIcon from "../assets/eye.png"
import GooglePlacesAutocomplete from 'react-google-places-autocomplete';

import Iconify from 'src/components/iconify/Iconify';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
// components

import Scrollbar from '../components/scrollbar';
// sections
import { UserListHead } from '../sections/@dashboard/user';
// mock
import moment from "moment"
import { useNavigate } from 'react-router-dom';
// ----------------------------------------------------------------------

const TABLE_HEAD = [
  { id: 'name', label: 'Name', alignRight: false },
  // { id: 'email', label: 'Email', alignRight: false },
  { id: 'phone', label: 'Phone Number', alignRight: false },
  { id: 'date', label: 'Date', alignRight: false },
  { id: 'time', label: 'Start Time', alignRight: false },
  { id: 'time', label: 'End Time', alignRight: false },

  { id: 'status', label: 'Status', alignRight: false },
  { id: 'action', label: 'Action', alignRight: false },
];

// ----------------------------------------------------------------------




export default function JobsPage() {
  const navigate = useNavigate()
  const [info, setInfo] = useState({
    name: "",
    email: '',
    phoneNumber: "",
    type: "",
    message: "",
    hearAboutUs: "",
    isSale: true,
    startTime: "",
    endTime: "",
    address: "",
    placeId: "",
    description: "",
    employeeIds: [],


  })
  const [deleteModal, setDeleteModal] = useState(false)
  const [employees, setEmployees] = useState([])
  const [jobs, setJobs] = useState([])
  const [old, setOld] = useState(false)


  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(20);
  const [data, setData] = useState([])
  const [loader, setLoader] = useState(false);
  const [edit, setEdit] = useState(false)
  const [tokens, setTokens] = useState(0)
  const [isDisabeld, setIsDisabeld] = useState(false)
  const [add, setAdd] = useState(false)
  const inputhandler = (event) => {
    if (event.label) {
      const name = "placeId"
      const value = event.value.place_id
      setInfo({ ...info, [name]: value })
    } else {
      const name = event.target.name;
      const value = event.target.value
      setInfo({ ...info, [name]: value })
    }

  }
  const handleCloseDelete = () => {
    setDeleteModal(false)
  };
  const handleFailure = (msg) => {
    toast.error(msg, {
      position: toast.POSITION.TOP_RIGHT,
      autoClose: 3000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
  };
  const handleSuccess = (msg) => {
    toast.success(msg, {
      position: toast.POSITION.TOP_RIGHT,
      autoClose: 3000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
  };
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };
  const handleClose = () => {
    setOld(false)
    setEdit(false);
    setAdd(false);
    setInfo({
      name: "",
      email: '',
      phoneNumber: "",
      type: "",
      message: "",
      hearAboutUs: "",
      isSale: true,
      description: "",
      employeeIds: [],
    })

  };

  const handleChangeRowsPerPage = (event) => {
    setPage(0);
    setRowsPerPage(parseInt(event.target.value, 10));
  };

  const updateData = async () => {
    setIsDisabeld(true)
    await axiosApi("PUT", `/inquiry/update-inquiry-with-task-by-id/${info._id}`, info)
      .then((res) => {
        console.log(res)
        handleSuccess("Job updated successfully")
        getData()
      })
      .catch((err) => {
        handleFailure("Error in updating Job")
        console.log(err);
      });
    handleClose()
    setIsDisabeld(false)
  }
  const addData = async () => {
    const body = { ...info, status: "assignment" }
    console.log("sending", body)
    setIsDisabeld(true)
    await axiosApi("POST", `/inquiry/create-inquiry-with-task`, body)
      .then((res) => {
        console.log(res)
        handleSuccess("Job added successfully")
        getData()
      })
      .catch((err) => {
        handleFailure("Error in adding Job")
        console.log(err);
      });
    handleClose()
    setIsDisabeld(false)
  }
  const oldData = async () => {
    const body = { ...info, status: "completed" }
    console.log("sending", body)
    setIsDisabeld(true)
    await axiosApi("POST", `/inquiry/create-inquiry`, body)
      .then((res) => {
        console.log(res)
        handleSuccess("Job added successfully")
        getData()
      })
      .catch((err) => {
        handleFailure("Error in adding Job")
        console.log(err);
      });
    handleClose()
    setIsDisabeld(false)
  }
  const del = async (id) => {
    await axiosApi("DELETE", `/inquiry/delete-inquiry-by-id/${deleteModal}`)
      .then((res) => {
        handleSuccess("Job deleted successfully")
        getData()
      })
      .catch((err) => {
        console.log(err);
        handleFailure("Error in deleting job")

      });
    handleCloseDelete()
  }
  const getEmployees = async () => {
    setLoader(true)
    await axiosApi("GET", `/employee/get-all-employee`)
      .then((res) => {
        console.log(res.data)
        setEmployees(res.data);
      })
      .catch((err) => {
        console.log(err);
      });
    setLoader(false)

  };
  const getData = async () => {
    setLoader(true)
    await axiosApi("GET", `/inquiry/get-all-sales`)
      .then((res) => {
        console.log(res.data)
        setData(res.data);
      })
      .catch((err) => {
        console.log(err);
      });
    setLoader(false)

  };


  useEffect(() => {
    getEmployees()
    getData()
  }, [])
  const handlePlaceSelect = (place) => {
    console.log(place)
    // setNearestPoint(place)
  };
  return (
    <>
      <Helmet>
        <title> Jobs  </title>
      </Helmet>

      <Container>
        <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
          <Typography style={{ color: "#D9B455" }} variant="h4" gutterBottom>
            Jobs
          </Typography>
          <div>
            <Button style={{ backgroundColor: "#D9B455", marginRight: "20px" }} onClick={() => { setOld(true) }} variant="contained" startIcon={<Iconify icon="eva:plus-fill" />}>
              Old Jobs
            </Button>
            <Button style={{ backgroundColor: "#D9B455" }} onClick={() => { setAdd(true) }} variant="contained" startIcon={<Iconify icon="eva:plus-fill" />}>
              New Jobs
            </Button>
          </div>
        </Stack>
        {loader ? <div style={{ height: "200px", display: "flex", justifyContent: "center", alignItems: "center" }}>
          <CircularProgress style={{ color: "#D9B455" }} />
        </div>
          :
          <Card>

            <Scrollbar>
              <TableContainer sx={{ minWidth: 800 }}>
                <Table>
                  <UserListHead
                    headLabel={TABLE_HEAD}
                    rowCount={data?.length}
                  />

                  <TableBody>
                    {data?.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)?.map((row, index) => {
                      const { name, email, phoneNumber, type, status, date, startTime, endTime } = row;

                      return (
                        <TableRow key={index}  >
                          <TableCell align="left">{name}</TableCell>
                          <TableCell align="left">{phoneNumber}</TableCell>
                          <TableCell align="left">{date ? moment(new Date(date)).format("YYYY-MM-DD") : "no date added"}</TableCell>

                          <TableCell align="left">{startTime}</TableCell>
                          <TableCell align="left">{endTime}</TableCell>

                          <TableCell align="left">{status}</TableCell>

                          <TableCell style={{ display: "flex" }} >
                            <img style={{ cursor: "pointer", marginLeft: "15px" }} src={EyeIcon} onClick={() => { navigate(`/dashboard/jobs/${row._id}`) }}></img>
                            <img style={{ cursor: "pointer", marginLeft: "15px" }} src={PencilIcon} onClick={() => { setInfo(row); setEdit(true) }}></img>
                            <img style={{ cursor: "pointer", marginLeft: "15px" }} src={DeleteIcon} onClick={() => { setDeleteModal(row._id) }}></img>

                          </TableCell>

                        </TableRow>
                      );
                    })}

                  </TableBody>
                </Table>
              </TableContainer>
            </Scrollbar>

            <TablePagination
              rowsPerPageOptions={[20, 50, 100]}
              component="div"
              count={data?.length}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
          </Card>
        }
        <Dialog
          open={edit || add || old}
          onClose={handleClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle style={{ color: "#D9B455" }} id="alert-dialog-title">
            {add ? "New Job" : old ? "Old Job" : "Update Job"}
          </DialogTitle>

          <DialogContent style={{ minWidth: "600px" }}>

            <div>
              <h3>Name</h3>
              <TextField onChange={inputhandler} value={info.name} name="name" style={{ width: "100%" }}></TextField>
            </div>
            <div>
              <h3>email</h3>
              <TextField onChange={inputhandler} value={info.email} name="email" style={{ width: "100%" }}></TextField>
            </div>
            <div>
              <h3>Phone Number</h3>
              <TextField onChange={inputhandler} value={info.phoneNumber} name="phoneNumber" style={{ width: "100%" }}></TextField>
            </div>
            <div>
              <h3>Type</h3>
              <TextField onChange={inputhandler} value={info.type} name="type" style={{ width: "100%" }}></TextField>
            </div>
            <div>
              <h3>Date</h3>
              <TextField type="date" onChange={inputhandler} value={moment(new Date(info.date)).format("YYYY-MM-DD")} name="date" style={{ width: "100%" }}></TextField>
            </div>
            <div>
              <h3>Start Time</h3>
              <TextField type="time" onChange={inputhandler} value={info.startTime} name="startTime" style={{ width: "100%" }}></TextField>
            </div>
            <div>
              <h3>End Time</h3>
              <TextField type="time" onChange={inputhandler} value={info.endTime} name="endTime" style={{ width: "100%" }}></TextField>
            </div>
            <div>
              <h3>Address</h3>
              <TextField type="text" onChange={inputhandler} value={info.address} name="address" style={{ width: "100%" }}></TextField>
            </div>
            <div>
              <h3>Nearest Point</h3>
              <div >
                <GooglePlacesAutocomplete
                  apiKey="AIzaSyAN_6e4jAOJNXNpYDjK5VrOaHLFadULobA"
                  autocompletionRequest={{
                  }}
                  selectProps={{
                    placeholder: 'Nearest Point',
                    name: "nearestPoint",
                    onChange: inputhandler
                  }}
                  onLoadFailed={(error) => {
                    console.error('Could not inject Google script', error);
                  }}


                />
              </div>
            </div>
            <div>
              <h3>How did you hear about us?</h3>
              <TextField onChange={inputhandler} value={info.hearAboutUs} name="hearAboutUs" style={{ width: "100%" }}></TextField>
            </div>
            <div>
              <h3>Message</h3>
              <TextField multiline={true} rows={5} onChange={inputhandler} value={info.message} name="message" style={{ width: "100%" }}></TextField>
            </div>
            {add ?
              <>
                <div>
                  <h3> Select Employees</h3>
                  <div>
                    {info.employeeIds?.map((item, index) => {
                      return (<div style={{ display: "flex", justifyContent: "space-between" }}>
                        <p>{item.name}</p>
                        <div onClick={(e) => { info.employeeIds.splice(index, 1); console.log(index); setInfo({ ...info, employeeIds: [...info.employeeIds] }) }} style={{ cursor: "pointer", display: "flex", justifyContent: "center", alignItems: "center" }}><img src={CloseIcon}></img></div>
                      </div>)
                    })}
                  </div>
                  <Select onChange={(e) => { setInfo({ ...info, employeeIds: [...info.employeeIds, { ...e.target.value, latitude: null, longitude: null }] }) }} name="employeeIds" style={{ width: "100%" }}>
                    {employees?.filter((item1) => !info.employeeIds?.some((item2) => item2._id === item1._id))?.map((item) => {
                      return (
                        <MenuItem value={item}>{item.name}</MenuItem>
                      )
                    })}
                  </Select>
                </div>
                <div>
                  <h3>Description</h3>
                  <TextField multiline={true} rows={5} onChange={inputhandler} value={info.description} name="description" style={{ width: "100%" }}></TextField>
                </div>
              </>
              : null}


          </DialogContent>

          <DialogActions>
            {add ?
              <Button style={{ color: "#D9B455" }} disabled={isDisabeld} onClick={() => addData()}>Add</Button>
              :
              old ?
                <Button style={{ color: "#D9B455" }} disabled={isDisabeld} onClick={() => oldData()}>Add</Button>
                :
                <Button style={{ color: "#D9B455" }} disabled={isDisabeld} onClick={() => updateData()}>Update</Button>
            }

          </DialogActions>
        </Dialog>
        <Dialog
          open={deleteModal}
          onClose={handleCloseDelete}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle style={{ color: "#D9B455" }} id="alert-dialog-title">
            {"Delete"}
          </DialogTitle>
          <DialogContent style={{ minWidth: "600px" }}>
            Are you sure you want to delete?


          </DialogContent>
          <DialogActions>
            <Button style={{ color: "#D9B455" }} onClick={() => { del() }}>Yes</Button>
            <Button style={{ color: "#D9B455" }} onClick={() => { handleCloseDelete() }}>No</Button>

          </DialogActions>
        </Dialog>
      </Container>
      <ToastContainer />

    </>
  );
}
