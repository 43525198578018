import { Helmet } from 'react-helmet-async';
import { useEffect, useState } from 'react';
// @mui
import {
    Card,
    Table,
    Stack,
    TableRow,
    TableBody,
    TableCell,
    Container,
    Typography,
    TableContainer,
    TablePagination,
    CircularProgress,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    TextField,
    Select,
    MenuItem,
    Grid
} from '@mui/material';
import Iconify from 'src/components/iconify/Iconify';


import { axiosApi } from 'src/service/apiEnviornment';
import Button from '@mui/material/Button';
import EyeIcon from "../assets/eye.png"
import DeleteIcon from "../assets/trash.png"
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
// components

import Scrollbar from '../components/scrollbar';
// sections
import { UserListHead } from '../sections/@dashboard/user';
// mock
import moment from "moment"
// ----------------------------------------------------------------------

const TABLE_HEAD = [
    { id: 'name', label: 'Name', alignRight: false },
    { id: 'email', label: 'Email', alignRight: false },
    { id: 'phone', label: 'Phone Number', alignRight: false },
    { id: 'action', label: 'Action', alignRight: false },
];

// ----------------------------------------------------------------------




export default function EmailPage() {
    const [info, setInfo] = useState({
        toSend: "",
        message: "",
        isDatabase: true,
        list: []
    })
    console.log("info", info)
    const [nearestPoint, setNearestPoint] = useState('')
    const [address, setAddress] = useState("")
    const [email, setEmail] = useState()
    const [endTime, setEndTime] = useState()
    const [startTime, setStartTime] = useState()

    const [deleteModal, setDeleteModal] = useState(false)
    const [isSaleModal, setIsSaleModal] = useState(false)
    const [isDisabeld, setIsDisabeld] = useState(false)


    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(20);
    const [data, setData] = useState([])
    const [loader, setLoader] = useState(false);
    const [details, setDetails] = useState(false)

    const inputhandler = (event) => {
        const name = event.target.name;
        const value = event.target.value
        console.log(name, value)
        if (name == "toSend" && (value == "manually" || value == "compose")) {
            setInfo({ ...info, isDatabase: false,list:[], [name]: value })

        }
        else if (name == "toSend" && value != "manually") {
            setInfo({ ...info, isDatabase: true,list:[], [name]: value })

        }
        else {

            setInfo({ ...info, [name]: value })
        }
    }
    const handleCloseIsSale = () => {
        setIsSaleModal(false)
    };
    const handleCloseDelete = () => {
        setDeleteModal(false)
    };

    const handleFailure = (msg) => {
        toast.error(msg, {
            position: toast.POSITION.TOP_RIGHT,
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
        });
    };
    const handleSuccess = (msg) => {
        toast.success(msg, {
            position: toast.POSITION.TOP_RIGHT,
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
        });
    };
    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };
    const handleClose = () => {
        setDetails(false);
        setInfo({
            toSend: "",
            message: "",
            isDatabase: true,
            list: []
        })

    };

    const handleChangeRowsPerPage = (event) => {
        setPage(0);
        setRowsPerPage(parseInt(event.target.value, 10));
    };

    const addEmail = () => {

        setInfo({...info,list:[...info.list,{email}]})
        setEmail("")
    }

    const getData = async () => {
        setLoader(true)
        await axiosApi("GET", `/inquiry/get-all-leads`)
            .then((res) => {
                console.log(res.data)
                setData(res.data);
            })
            .catch((err) => {
                console.log(err);
            });
        setLoader(false)

    };

    const handlePlaceSelect = (place) => {
        setNearestPoint(place)
    };
    const addCustomer = (customer) => {
        console.log(customer)
        const foundObject = info.list.find(item => item.email === customer.email);

        if (!foundObject) {
            setInfo({ ...info, list: [...info.list, customer] })
        }
    };
    const deleteCustomer = (customer) => {
        console.log(info.list)
        console.log(customer)
        const ind = info.list.indexOf(customer)
        console.log(ind)
        info.list.splice(ind, 1)
        setInfo({ ...info, list: [...info.list] })
    };
    const sendEmails = async () => {
        setLoader(true);
        await axiosApi('POST', `/email/send-emails`, info)
            .then((res) => {
                handleSuccess("Emails sent successfully")


            })
            .catch((err) => {
                handleFailure("Error in sending mails")
                console.log(err);
            });
        const reset = {
            toSend: info.toSend,
            message: "",
            isDatabase: info.isDatabase,
            list: []
        }
        setInfo({ ...reset })
        setLoader(false);
    }
    useEffect(() => {
        getData()
    }, [])

    return (
        <>
            <Helmet>
                <title> Emails  </title>
            </Helmet>

            <Container>
                <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
                    <Typography style={{ color: "#D9B455" }} variant="h4" gutterBottom>
                        Emails
                    </Typography>
                    <div style={{ display: "flex" }}>
                        <h3 style={{ marginRight: "10px" }}>To Send : </h3>
                        <Select name="toSend" onChange={inputhandler} style={{ width: "200px" }} >
                            <MenuItem value="all">All</MenuItem>
                            <MenuItem value="customers">Customers</MenuItem>
                            <MenuItem value="non-customers">Non-Customers</MenuItem>
                            <MenuItem value="manually">Manually</MenuItem>
                            <MenuItem value="compose">Compose</MenuItem>

                        </Select>
                    </div>
                    <Button
                        style={{ backgroundColor: "#D9B455" }}
                        onClick={() => {
                            sendEmails(true);
                        }}
                        variant="contained"
                        startIcon={<Iconify icon="eva:plus-fill" />}
                    >
                        Send Emails
                    </Button>

                </Stack>
                <Stack>
                    <Grid>
                        <h3>Email Body</h3>
                        <TextField name="message" value={info.message} onChange={inputhandler} style={{ width: "100%" }} multiline={true} rows={10}></TextField>
                    </Grid>
                    <Grid >
                        {info.list.length > 0 ?
                            <>
                                <h3>Customers</h3>
                                <div >
                                    {info.list?.map((item, index) => {
                                        return (
                                            <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center", borderBottom: "1px gray solid", padding: "0px 20px" }}>
                                                <span style={{ display: "flex" }}> <p style={{ marginRight: "30px" }}>{index + 1} )</p> <p>{item.email}</p></span>
                                                <Button onClick={() => deleteCustomer(item)} style={{ height: "40px", width: "20px", backgroundColor: "#D9B455" }} variant='contained'>X</Button>
                                            </div>

                                        )
                                    })}
                                </div>
                            </>
                            : null}
                    </Grid>

                </Stack>
                {info.toSend == "manually" ?
                    <Card sx={{ marginTop: "50px" }}>

                        <Scrollbar>
                            <TableContainer sx={{ minWidth: 800 }}>
                                <Table>
                                    <UserListHead
                                        headLabel={TABLE_HEAD}
                                        rowCount={data?.length}
                                    />

                                    <TableBody>
                                        {data?.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)?.map((row, index) => {
                                            const { name, email, phoneNumber, type } = row;

                                            return (
                                                <TableRow key={index}  >
                                                    <TableCell align="left">{name}</TableCell>
                                                    <TableCell align="left">{email}</TableCell>
                                                    <TableCell align="left">{phoneNumber}</TableCell>
                                                    <TableCell align="left">
                                                        <Button style={{ backgroundColor: "#D9B455" }} variant='contained' onClick={() => addCustomer(row)}>Add</Button>
                                                    </TableCell>
                                                </TableRow>
                                            );
                                        })}

                                    </TableBody>
                                </Table>
                            </TableContainer>
                        </Scrollbar>

                        <TablePagination
                            rowsPerPageOptions={[20, 50, 100]}
                            component="div"
                            count={data?.length}
                            rowsPerPage={rowsPerPage}
                            page={page}
                            onPageChange={handleChangePage}
                            onRowsPerPageChange={handleChangeRowsPerPage}
                        />
                    </Card>
                    : null}


                {info.toSend == "compose" ?
                    <Stack style={{marginTop:"25px"}}>
                        <Grid container >
                            <Grid xs={10}>
                                <TextField name="email" value={email} onChange={(e) => setEmail(e.target.value)} style={{ width: "100%" }}></TextField>
                            </Grid>
                            <Grid style={{display:"flex",justifyContent:'center',alignItems:'center'}} xs={2}>
                                <Button style={{ backgroundColor: "#D9B455" }} variant='contained' onClick={() => addEmail()}>Add</Button>
                            </Grid>
                        </Grid>


                    </Stack>


                    : null}



                <Dialog
                    open={deleteModal}
                    onClose={handleCloseDelete}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                >
                    <DialogTitle id="alert-dialog-title">
                        {"Delete"}
                    </DialogTitle>
                    <DialogContent style={{ minWidth: "600px" }}>
                        Are you sure you want to delete?


                    </DialogContent>
                    <DialogActions>
                        {/* <Button onClick={() => { del() }}>Yes</Button> */}
                        <Button onClick={() => { handleCloseDelete() }}>No</Button>

                    </DialogActions>
                </Dialog>
            </Container>
            <ToastContainer />

        </>
    );
}
