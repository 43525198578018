import { Helmet } from 'react-helmet-async';
import { useEffect, useState } from 'react';
// @mui
import {
  Card,
  Table,
  Stack,
  TableRow,
  TableBody,
  TableCell,
  Container,
  Typography,
  TableContainer,
  TablePagination,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
  Select,
  MenuItem
} from '@mui/material';
import CloseIcon from "../assets/close.png"

import { axiosApi } from 'src/service/apiEnviornment';
import Button from '@mui/material/Button';
import PencilIcon from "../assets/edit.png"
import EyeIcon from "../assets/eye.png"
import DeleteIcon from "../assets/trash.png"
import Iconify from 'src/components/iconify/Iconify';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
// components

import Scrollbar from '../components/scrollbar';
// sections
import { UserListHead } from '../sections/@dashboard/user';
// mock
import moment from "moment"
import { useNavigate } from 'react-router-dom';
// ----------------------------------------------------------------------

const TABLE_HEAD = [
  { id: 'make', label: 'Manager', alignRight: false },
  { id: 'model', label: 'Manager Phone', alignRight: false },
  { id: 'waterInGallons', label: 'Customer', alignRight: false },
  { id: 'registeredOn', label: 'Customer Phone', alignRight: false },
  { id: 'action', label: 'Action', alignRight: false },
];

// ----------------------------------------------------------------------




export default function TaskPage() {
  const navigate=useNavigate()
  const [info, setInfo] = useState({
    employeeIds: [],
    jobId: '',
    description:"",
    // _id:""
  })
  // console.log("info",info)
  const [deleteModal, setDeleteModal] = useState(false)
  const [employees, setEmployees] = useState([])
  const [jobs, setJobs] = useState([])



  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(20);
  const [data, setData] = useState([])
  const [loader, setLoader] = useState(false);
  const [edit, setEdit] = useState(false)
  const [tokens, setTokens] = useState(0)
  const [isDisabeld, setIsDisabeld] = useState(false)
  const [add, setAdd] = useState(false)
  const inputhandler = (event) => {
    const name = event.target.name;
    const value = event.target.value
    setInfo({ ...info, [name]: value })
  }
  const handleCloseDelete = () => {
    setDeleteModal(false)
  };
  const handleFailure = (msg) => {
    toast.error(msg, {
      position: toast.POSITION.TOP_RIGHT,
      autoClose: 3000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
  };
  const handleSuccess = (msg) => {
    toast.success(msg, {
      position: toast.POSITION.TOP_RIGHT,
      autoClose: 3000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
  };
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };
  const handleClose = () => {
    setEdit(false);
    setAdd(false);
    setInfo({
      employeeIds: [],
      jobId: '',
      description:"",
      // _id:""


    })

  };

  const handleChangeRowsPerPage = (event) => {
    setPage(0);
    setRowsPerPage(parseInt(event.target.value, 10));
  };

  const updateData = async () => {
    console.log(info)
    setIsDisabeld(true)
    await axiosApi("PUT", `/task/update-task-by-id/${info.id}`, info)
      .then((res) => {
        console.log(res)
        handleSuccess("Scheduler updated successfully")
        getData()
      })
      .catch((err) => {
        handleFailure("Error in updating Scheduler")
        console.log(err);
      });
    handleClose()
    setIsDisabeld(false)
  }
  const addData = async () => {
    console.log(info)
    setIsDisabeld(true)
    await axiosApi("POST", `/task/create-task`, info)
      .then((res) => {
        console.log(res)
        handleSuccess("Scheduler added successfully")
        getData()
      })
      .catch((err) => {
        handleFailure("Error in adding Scheduler")
        console.log(err);
      });
    handleClose()
    setIsDisabeld(false)
  }
  const del = async (id) => {
    await axiosApi("DELETE", `/task/delete-task-by-id/${deleteModal}`)
      .then((res) => {
        handleSuccess("Scheduler deleted successfully")
        getData()
      })
      .catch((err) => {
        console.log(err);
      });
    handleCloseDelete()
  }
  const getData = async () => {
    setLoader(true)
    await axiosApi("GET", `/task/get-all-task`)
      .then((res) => {
        console.log(res.data)
        setData(res.data);
      })
      .catch((err) => {
        console.log(err);
      });
    setLoader(false)

  };
  const getEmployees = async () => {
    setLoader(true)
    await axiosApi("GET", `/employee/get-all-employee`)
      .then((res) => {
        console.log(res.data)
        setEmployees(res.data);
      })
      .catch((err) => {
        console.log(err);
      });
    setLoader(false)

  };
  const getJobs = async () => {
    setLoader(true)
    await axiosApi("GET", `/inquiry/get-all-new-jobs`)
      .then((res) => {
        console.log(res.data)
        setJobs(res.data);
      })
      .catch((err) => {
        console.log(err);
      });
    setLoader(false)

  };

  useEffect(() => {
    getData()
  }, [])
  useEffect(() => {
    getEmployees()
    getJobs()
  }, [])
  return (
    <>
      <Helmet>
        <title> Scheduler  </title>
      </Helmet>

      <Container>
        <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
          <Typography style={{color:"#D9B455"}} variant="h4" gutterBottom>
            Scheduler
          </Typography>
          {/* <Button style={{backgroundColor:"#D9B455"}} onClick={() => { setAdd(true) }} variant="contained" startIcon={<Iconify icon="eva:plus-fill" />}>
            New Scheduler
          </Button> */}
        </Stack>
        {loader ? <div style={{ height: "200px", display: "flex", justifyContent: "center", alignItems: "center" }}>
          <CircularProgress style={{color:"#D9B455"}}/>
        </div>
          :
          <Card>

            <Scrollbar>
              <TableContainer sx={{ minWidth: 800 }}>
                <Table>
                  <UserListHead
                    headLabel={TABLE_HEAD}
                    rowCount={data?.length}
                  />

                  <TableBody>
                    {data?.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)?.map((row, index) => {
                      const { employeeIds, jobId,description,_id } = row;

                      return (
                        <TableRow key={index}  >
                          <TableCell align="left">{(employeeIds?.length>0) ? employeeIds[0].name : "Not Assigned"}</TableCell>
                          <TableCell align="left">{(employeeIds?.length>0) ? employeeIds[0].phoneNumber : "Not Found"}</TableCell>

                          <TableCell align="left">{(jobId?.name) ? jobId.name : "Not Found"}</TableCell>
                          <TableCell align="left">{(jobId?.phoneNumber) ? jobId.phoneNumber : "Not Found"}</TableCell>


                          <TableCell style={{ display: "flex" }} >
                          <img style={{ cursor: "pointer", marginLeft: "15px" }} src={EyeIcon} onClick={() => { navigate(`/dashboard/task/${row._id}`) }}></img>

                            <img style={{ cursor: "pointer", marginLeft: "15px" }} src={PencilIcon} onClick={() => { setInfo({employeeIds:employeeIds,jobId:jobId._id,description:description,id:_id}); setEdit(true) }}></img>
                            <img style={{ cursor: "pointer", marginLeft: "15px" }} src={DeleteIcon} onClick={() => { setDeleteModal(row._id) }}></img>

                          </TableCell>
                        </TableRow>
                      );
                    })}

                  </TableBody>
                </Table>
              </TableContainer>
            </Scrollbar>

            <TablePagination
              rowsPerPageOptions={[20, 50, 100]}
              component="div"
              count={data?.length}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
          </Card>
        }
        <Dialog
          open={edit || add}
          onClose={handleClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle style={{color:"#D9B455"}} id="alert-dialog-title">
            {add ? "New Scheduler" : "Update Scheduler"}
          </DialogTitle>
          <DialogContent style={{ minWidth: "600px" }}>
            <div>
              <h3> Select Employees</h3>
              <div>
                {info.employeeIds?.map((item,index)=>{
                  return(<div style={{display:"flex",justifyContent:"space-between"}}>
                    <p>{item.name}</p>
                  <div onClick={(e)=>{info.employeeIds.splice(index,1);console.log(index);setInfo({...info,employeeIds:[...info.employeeIds]})}} style={{cursor:"pointer",display:"flex",justifyContent:"center",alignItems:"center"}}><img src={CloseIcon}></img></div> 
                    </div>)
                })}
              </div>
              <Select onChange={(e)=>{setInfo({...info,employeeIds:[...info.employeeIds,{...e.target.value,latitude:null,longitude:null}]})}}  name="employeeIds" style={{ width: "100%" }}>
                {employees?.filter((item1)=>!info.employeeIds?.some((item2)=>item2._id === item1._id))?.map((item) => {
                  return (
                    <MenuItem value={item}>{item.name}</MenuItem>
                  )
                })}
              </Select>
            </div>
            {add?
                  <div>
                  <h3>  Select Job</h3>
                  <Select onChange={inputhandler} value={info.jobId} name="jobId" style={{ width: "100%" }}>
                    {jobs?.map((item) => {
                      return (
                        <MenuItem value={item._id}>{item.name}</MenuItem>
                      )
                    })}
                  </Select>
                </div>
            :null}
      
            <div>
              <h3>Work Order</h3>
              <TextField multiline={true} rows={5} onChange={inputhandler} value={info.description} name="description" style={{ width: "100%" }}></TextField>
            </div>

          </DialogContent>
          <DialogActions>
            {add ?
              <Button style={{color:"#D9B455"}} disabled={isDisabeld} onClick={() => addData()}>Add</Button>
              :
              <Button style={{color:"#D9B455"}} disabled={isDisabeld} onClick={() => updateData()}>Update</Button>
            }

          </DialogActions>
        </Dialog>
        <Dialog
          open={deleteModal}
          onClose={handleCloseDelete}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle style={{color:"#D9B455"}} id="alert-dialog-title">
            {"Delete"}
          </DialogTitle>
          <DialogContent style={{ minWidth: "600px" }}>
            Are you sure you want to delete?


          </DialogContent>
          <DialogActions>
            <Button style={{color:"#D9B455"}} onClick={() => { del() }}>Yes</Button>
            <Button style={{color:"#D9B455"}} onClick={() => { handleCloseDelete() }}>No</Button>

          </DialogActions>
        </Dialog>
      </Container>
      <ToastContainer />

    </>
  );
}
