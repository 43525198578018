import { Helmet } from 'react-helmet-async';
import { useEffect, useState } from 'react';
// @mui
import {
  Card,
  Table,
  Stack,
  TableRow,
  TableBody,
  TableCell,
  Container,
  Typography,
  TableContainer,
  TablePagination,
  CircularProgress,
  Popover,
  Select,
  MenuItem,
  FormControl,
} from '@mui/material';
import { DateRangePicker } from 'react-date-range';
import { axiosApi } from 'src/service/apiEnviornment';
import Button from '@mui/material/Button';
import PencilIcon from '../assets/edit.png';
import DeleteIcon from '../assets/trash.png';
import Iconify from 'src/components/iconify/Iconify';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import moment from 'moment';
// components

import Scrollbar from '../components/scrollbar';
// sections
import { UserListHead } from '../sections/@dashboard/user';
import { useLocation, useNavigate } from 'react-router-dom';
// mock

// ----------------------------------------------------------------------

const TABLE_HEAD = [
  { id: 'taskId', label: 'Scheduler id', alignRight: false },
  { id: 'client name', label: 'Client', alignRight: false },
  { id: 'startDate', label: 'Start Time', alignRight: false },
  { id: 'endDate', label: 'End Time', alignRight: false },
  { id: 'hours', label: 'Hours', alignRight: false },
];

// ----------------------------------------------------------------------

export default function TrackingPage() {
  const navigate = useNavigate();
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(20);
  const [loader, setLoader] = useState(false);
  const [mode, setMode] = useState('day');
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };
  const handleModeChange = (event) => {
    setMode(event.target.value);
  };

  const location = useLocation();

  const [trackingData, setTrackingData] = useState();

  const handleChangeRowsPerPage = (event) => {
    setPage(0);
    setRowsPerPage(parseInt(event.target.value, 10));
  };

  function extractDateAndTime(timestamp) {
    const dateObject = new Date(timestamp);

    // Extract date components
    const year = dateObject.getFullYear();
    const month = dateObject.getMonth() + 1; // Months are zero-based, so add 1
    const day = dateObject.getDate();

    // Extract time components
    const hours = dateObject.getUTCHours();
    const minutes = dateObject.getUTCMinutes();
    const seconds = dateObject.getUTCSeconds();

    return {
      date: `${year}-${month}-${day}`,
      time: `${hours}:${minutes}:${seconds}`,
    };
  }

  function convertDecimalHoursToHoursAndMinutes(decimalHours) {
    const hours = Math.floor(decimalHours);
    const minutes = Math.round((decimalHours - hours) * 60);
    return { hours, minutes };
  }
  const currentDate = new Date();
  currentDate.setHours(0, 0, 0, 0);
  const endOfDay = new Date(currentDate);
  endOfDay.setHours(23, 59, 59, 999);
  const [selectedDates, setSelectedDates] = useState([
    {
      startDate: currentDate,
      endDate: endOfDay,
      key: 'selection',
    },
  ]);
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const handleClosePopOver = () => {
    setAnchorEl(null);
  };
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleDateSelect = (ranges) => {
    setSelectedDates([ranges.selection]);
  };

  const getData = async () => {
    setLoader(true);
    await axiosApi(
      'GET',
      `/tracking/get-tracking/${location?.state?.userId}?startDate=${selectedDates[0].startDate}&endDate=${selectedDates[0].endDate}`
    )
      .then((res) => {
        setTrackingData(res.data);
      })
      .catch((err) => {
        console.log(err);
      });
    setLoader(false);
  };

  useEffect(() => {
    getData();
  }, [selectedDates]);

  return (
    <>
      <Helmet>
        <title> Employee </title>
      </Helmet>

      <Container>
        <Stack direction="row" alignItems="center" justifyContent="space-between" mb={2}>
          <Typography style={{ color: '#D9B455' }} variant="h4" gutterBottom>
            Employee Report
          </Typography>
          <div>
            <Button
              style={{
                backgroundColor: '#D9B455',
                border: 'none',
                cursor: 'pointer',
                padding: 5,
                color: '#fff',
                borderRadius: 5,
              }}
              onClick={handleClick}
            >
              Select Range
            </Button>
            <div>
              <Popover sx={{ marginTop: 4 }} open={open} anchorEl={anchorEl} onClose={handleClosePopOver}>
                <DateRangePicker
                  onChange={handleDateSelect}
                  showSelectionPreview={true}
                  moveRangeOnFirstSelection={false}
                  ranges={selectedDates}
                  rangeColors={['#D9B455']}
                />
              </Popover>
            </div>
          </div>
        </Stack>
        <div>
          <Typography mb={4}> Employee id: {location.state.userId}</Typography>
        </div>
        {loader ? (
          <div style={{ height: '200px', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
            <CircularProgress style={{ color: '#D9B455' }} />
          </div>
        ) : (
          <Card>
            <Scrollbar>
              <TableContainer sx={{ minWidth: 800 }}>
                <Table>
                  <UserListHead headLabel={TABLE_HEAD} rowCount={trackingData?.taskWise?.length} />

                  <TableBody>
                    {trackingData?.taskWise
                      ?.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                      ?.map((row, index) => {
                        const { taskId, hours, startTime, endTime } = row;

                        return (
                          <TableRow key={index}>
                            <TableCell
                              color="primary"
                              sx={{ cursor: 'pointer', color: '#2265d1' }}
                              onClick={() => {
                                navigate(`/dashboard/task/${taskId._id}`);
                              }}
                              align="left"
                            >
                              {taskId._id}
                            </TableCell>
                            <TableCell align="left">{taskId.jobId.name}</TableCell>

                            <TableCell align="left">
                              {/* {extractDateAndTime(startTime).date} &nbsp; &nbsp; &nbsp; */}
                              {extractDateAndTime(startTime).time}
                            </TableCell>
                            <TableCell align="left">
                              {/* {extractDateAndTime(endTime).date} &nbsp; &nbsp; &nbsp; */}
                              {extractDateAndTime(endTime).time}
                            </TableCell>
                            <TableCell align="left">
                              {convertDecimalHoursToHoursAndMinutes(hours).hours} hrs &nbsp;
                              {convertDecimalHoursToHoursAndMinutes(hours).minutes} mins
                            </TableCell>
                          </TableRow>
                        );
                      })}
                  </TableBody>
                </Table>
              </TableContainer>
              <div>
                <Typography mt={2} textAlign={'right'} mr={10}>
                  Total hours: {convertDecimalHoursToHoursAndMinutes(trackingData?.totalHrs).hours} hrs &nbsp;
                  {convertDecimalHoursToHoursAndMinutes(trackingData?.totalHrs).minutes} mins
                </Typography>
              </div>
            </Scrollbar>

            <TablePagination
              rowsPerPageOptions={[20, 50, 100]}
              component="div"
              count={trackingData?.taskWise?.length}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
          </Card>
        )}
      </Container>

      <ToastContainer />
    </>
  );
}
