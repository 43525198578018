import { useEffect, useRef, useState } from 'react';
import withDragAndDrop from 'react-big-calendar/lib/addons/dragAndDrop';
import 'react-big-calendar/lib/css/react-big-calendar.css';
import { Helmet } from 'react-helmet-async';
import moment from 'moment';
import GooglePlacesAutocomplete from 'react-google-places-autocomplete';
import 'react-big-calendar/lib/addons/dragAndDrop/styles.css';
// import { Calendar, momentLocalizer } from 'react-big-calendar';
import { Calendar, momentLocalizer } from 'react-big-calendar';
import { axiosApi } from 'src/service/apiEnviornment';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import CustomToolbar from 'src/components/big-calendar/CustomToolBar';
import { useNavigate } from 'react-router-dom';
import Iconify from 'src/components/iconify/Iconify';
import CloseIcon from '../assets/close.png';

import {
  Card,
  Table,
  Stack,
  TableRow,
  TableBody,
  TableCell,
  Container,
  Typography,
  TableContainer,
  TablePagination,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
  Select,
  MenuItem,
  Button,
} from '@mui/material';
const localizer = momentLocalizer(moment);
const DragAndDropCalendar = withDragAndDrop(Calendar);
const handleFailure = (msg) => {
  toast.error(msg, {
    position: toast.POSITION.TOP_RIGHT,
    autoClose: 3000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
  });
};

const handleSuccess = (msg) => {
  toast.success(msg, {
    position: toast.POSITION.TOP_RIGHT,
    autoClose: 3000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
  });
};
const combinedDate = (date, time) => {
  const dateObject = new Date(date);

  const year = dateObject.getFullYear();
  const month = dateObject.getMonth();
  const day = dateObject.getDate();

  // Split the time string into hours and minutes
  const [hours, minutes] = time.split(':');

  // Create a new Date object with the combined date and time
  const combinedDate = new Date(year, month, day, hours, minutes);
  return combinedDate;
};

export default function SchedularPage() {
  const [employees, setEmployees] = useState([]);
  const [info, setInfo] = useState({
    name: '',
    email: '',
    phoneNumber: '',
    type: '',
    message: '',
    hearAboutUs: '',
    isSale: true,
    startTime: '',
    endTime: '',
    address: '',
    placeId: '',
    description: '',
    employeeIds: [],
  });
  const [eventState, setEventState] = useState({
    events: null,
  });
  const [isDisabeld, setIsDisabeld] = useState(false);
  const currentView = useRef("month");

  const [resizeState, setResizeState] = useState(false);
  const [add, setAdd] = useState(false);
  const [data, setData] = useState([]);
  const [loader, setLoader] = useState(false);
  // const [events,setEvents]=useState()
  const navigate = useNavigate();

  const moveEvent = async ({ event, start, end, isAllDay: droppedOnAllDaySlot }) => {
    const { events } = eventState;

    const idx = events.indexOf(event);
    let allDay = event.allDay;

    if (!event.allDay && droppedOnAllDaySlot) {
      allDay = true;
    } else if (event.allDay && !droppedOnAllDaySlot) {
      allDay = false;
    }
    const updatedEvent = { ...event, start, end, allDay };

    const dayOfWeekStart = moment(start).day();
    const dayOfWeekEnd = moment(end).day();

    // if (dayOfWeekStart !== 0 && dayOfWeekStart !== 6 && dayOfWeekEnd !== 0 && dayOfWeekEnd !== 6) {
    const nextEvents = [...events];
    nextEvents.splice(idx, 1, updatedEvent);

    setEventState({
      events: nextEvents,
    });
    var startDateUpdated = new Date(updatedEvent.start);
    var hours = startDateUpdated.getHours();
    var minutes = startDateUpdated.getMinutes();
    var startTimeUpdated = `${hours.toString().padStart(2, '0')}:${minutes.toString().padStart(2, '0')}`;
    startDateUpdated.setHours(0, 0, 0, 0);

    var endDateUpdated = new Date(updatedEvent.end);
    var hours = endDateUpdated.getHours();
    var minutes = endDateUpdated.getMinutes();
    var endTimeUpdated = `${hours.toString().padStart(2, '0')}:${minutes.toString().padStart(2, '0')}`;
    endDateUpdated.setHours(0, 0, 0, 0);

    await axiosApi('PUT', `/inquiry/update-inquiry-by-id/${updatedEvent.id}`, {
      startTime: startTimeUpdated,
      endTime: endTimeUpdated,
      date: startDateUpdated,
    })
      .then((res) => {
        handleSuccess('Schedule updated successfully');
        getData();
      })
      .catch((err) => {
        handleFailure('Error in updating schedule');
        console.log(err);
      });
  };

  const resizeEvent = async ({ event, start, end }) => {
    const { events } = eventState;
    console.log('nextEvents', event);

    const dayOfWeekStart = moment(start).day();
    const dayOfWeekEnd = moment(end).day();
    // if (dayOfWeekStart !== 0 && dayOfWeekStart !== 6 && dayOfWeekEnd !== 0 && dayOfWeekEnd !== 6) {
    const nextEvents = events?.map((existingEvent) => {
      return existingEvent.id === event.id ? { ...existingEvent, start, end } : existingEvent;
    });

    setEventState({
      events: nextEvents,
    });
    var startDateUpdated = new Date(start);
    var hours = startDateUpdated.getHours();
    var minutes = startDateUpdated.getMinutes();
    var startTimeUpdated = `${hours.toString().padStart(2, '0')}:${minutes.toString().padStart(2, '0')}`;
    startDateUpdated.setHours(0, 0, 0, 0);

    var endDateUpdated = new Date(end);
    var hours = endDateUpdated.getHours();
    var minutes = endDateUpdated.getMinutes();
    var endTimeUpdated = `${hours.toString().padStart(2, '0')}:${minutes.toString().padStart(2, '0')}`;
    endDateUpdated.setHours(0, 0, 0, 0);

    await axiosApi('PUT', `/inquiry/update-inquiry-by-id/${event.id}`, {
      startTime: startTimeUpdated,
      endTime: endTimeUpdated,
      date: startDateUpdated,
    })
      .then((res) => {
        handleSuccess('Schedule updated successfully');
        getData();
      })
      .catch((err) => {
        handleFailure('Error in updating schedule');
        console.log(err);
      });
  };

  // }

  const newEvent = (event) => {
    console.log('onClickSlot===>', event);
  };

  const handleViewChange = (newView) => {
    currentView.current = newView;
    if (newView == 'day') {
      setResizeState(true);
    } else {
      setResizeState(false);
    }
  };
  const getData = async () => {
    setLoader(true);
    await axiosApi('GET', `/task/get-all-task`)
      .then((res) => {
        let updatedEvents = [];
        setData(res.data);
        res.data?.map((item, index) => {
          const start =
            item.jobId?.date && item?.jobId?.startTime
              ? combinedDate(item.jobId.date, item.jobId.startTime)
              : combinedDate('Sun Sep 24 2023 00:00:00', '13:30');
          const end =
            item.jobId?.date && item.jobId?.endTime
              ? combinedDate(item.jobId.date, item.jobId.endTime)
              : combinedDate('Sun Sep 24 2023 00:00:00', '13:30');
          const body = {
            jobType: item.jobId?.type ? item.jobId.type : 'activity',
            id: item.jobId?._id,
            start: start,
            end: end,
            taskId: item._id,
            title: item.jobId?.name,
          };
          updatedEvents.push(body);
        });
        setEventState({ events: updatedEvents });
      })
      .catch((err) => {
        console.log("err",err);
      });
    setLoader(false);
  };
  useEffect(() => {
    getEmployees();
    getData();
  }, []);

  const handleEventClick = (event) => {
    console.log(event);
    navigate(`/dashboard/task/${event.taskId}`);
  };
  const handleClose = () => {
    setAdd(false);
    setInfo({
      name: '',
      email: '',
      phoneNumber: '',
      type: '',
      message: '',
      hearAboutUs: '',
      isSale: true,
      description: '',
      employeeIds: [],
    });
  };
  const inputhandler = (event) => {
    if (event.label) {
      const name = 'placeId';
      const value = event.value.place_id;
      setInfo({ ...info, [name]: value });
    } else {
      const name = event.target.name;
      const value = event.target.value;
      setInfo({ ...info, [name]: value });
    }
  };
  const getEmployees = async () => {
    setLoader(true);
    await axiosApi('GET', `/employee/get-all-employee`)
      .then((res) => {
        setEmployees(res.data);
      })
      .catch((err) => {
        console.log(err);
      });
    setLoader(false);
  };
  const addData = async () => {
    const body = { ...info, status: 'assignment' };
    console.log('sending', body);
    setIsDisabeld(true);
    await axiosApi('POST', `/inquiry/create-inquiry-with-task`, body)
      .then((res) => {
        console.log(res);
        handleSuccess('Job added successfully');
        getData();
      })
      .catch((err) => {
        handleFailure('Error in adding Job');
        console.log(err);
      });
    handleClose();
    setIsDisabeld(false);
  };
  const oldData = async () => {
    const body = { ...info, status: 'assignment' };
    console.log('sending', body);
    setIsDisabeld(true);
    await axiosApi('POST', `/inquiry/create-inquiry-with-task`, body)
      .then((res) => {
        console.log(res);
        handleSuccess('Job added successfully');
        getData();
      })
      .catch((err) => {
        handleFailure('Error in adding Job');
        console.log(err);
      });
    handleClose();
    setIsDisabeld(false);
  };
  const EventComponent = ({ event }) => {
    return (
      <div>
        {currentView.current === 'month' && (
          <>
            <span style={{ fontSize: '12px' }}>
              {moment(event.start).format('hh:mm A')} - {moment(event.end).format('hh:mm A')}
            </span>
            <br />
          </>
        )}

        <span>{event.title}</span>
      </div>
    );
  };
  return (
    <div>
      <Helmet>
        <title> Calendar </title>
      </Helmet>
      <Container>
        <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
          <Typography style={{ color: '#D9B455' }} variant="h4" gutterBottom>
            Calendar
          </Typography>
          <Button
            style={{ backgroundColor: '#D9B455' }}
            onClick={() => {
              setAdd(true);
            }}
            variant="contained"
            startIcon={<Iconify icon="eva:plus-fill" />}
          >
            New Job
          </Button>
        </Stack>
      </Container>
      {eventState.events ? (
        <DragAndDropCalendar
          localizer={localizer}
          selectable
          resizable={resizeState}
          events={eventState.events}
          onEventResize={resizeEvent}
          onSelectSlot={newEvent}
          onEventDrop={moveEvent}
          startAccessor="start"
          endAccessor="end"
          onView={handleViewChange}
          components={{
            toolbar: CustomToolbar,
            event: EventComponent,
          }}
          onSelectEvent={handleEventClick}
          style={{ height: '80vh' }}
        />
      ) : null}
      <ToastContainer />
      <Dialog
        open={add}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle style={{ color: '#D9B455' }} id="alert-dialog-title">
          {add ? 'New Job' : 'Update Job'}
        </DialogTitle>

        <DialogContent style={{ minWidth: '600px' }}>
          <div>
            <h3>Name</h3>
            <TextField onChange={inputhandler} value={info.name} name="name" style={{ width: '100%' }}></TextField>
          </div>
          <div>
            <h3>email</h3>
            <TextField onChange={inputhandler} value={info.email} name="email" style={{ width: '100%' }}></TextField>
          </div>
          <div>
            <h3>Phone Number</h3>
            <TextField
              onChange={inputhandler}
              value={info.phoneNumber}
              name="phoneNumber"
              style={{ width: '100%' }}
            ></TextField>
          </div>
          <div>
            <h3>Type</h3>
            <TextField onChange={inputhandler} value={info.type} name="type" style={{ width: '100%' }}></TextField>
          </div>
          <div>
            <h3>Date</h3>
            <TextField
              type="date"
              onChange={inputhandler}
              value={moment(new Date(info.date)).format('YYYY-MM-DD')}
              name="date"
              style={{ width: '100%' }}
            ></TextField>
          </div>
          <div>
            <h3>Start Time</h3>
            <TextField
              type="time"
              onChange={inputhandler}
              value={info.startTime}
              name="startTime"
              style={{ width: '100%' }}
            ></TextField>
          </div>
          <div>
            <h3>End Time</h3>
            <TextField
              type="time"
              onChange={inputhandler}
              value={info.endTime}
              name="endTime"
              style={{ width: '100%' }}
            ></TextField>
          </div>
          <div>
            <h3>Address</h3>
            <TextField
              type="text"
              onChange={inputhandler}
              value={info.address}
              name="address"
              style={{ width: '100%' }}
            ></TextField>
          </div>
          <div>
            <h3>Nearest Point</h3>
            <div>
              <GooglePlacesAutocomplete
                apiKey="AIzaSyAN_6e4jAOJNXNpYDjK5VrOaHLFadULobA"
                autocompletionRequest={{}}
                selectProps={{
                  placeholder: 'Nearest Point',
                  name: 'nearestPoint',
                  onChange: inputhandler,
                }}
                onLoadFailed={(error) => {
                  console.error('Could not inject Google script', error);
                }}
              />
            </div>
          </div>
          <div>
            <h3>How did you hear about us?</h3>
            <TextField
              onChange={inputhandler}
              value={info.hearAboutUs}
              name="hearAboutUs"
              style={{ width: '100%' }}
            ></TextField>
          </div>
          <div>
            <h3>Message</h3>
            <TextField
              multiline={true}
              rows={5}
              onChange={inputhandler}
              value={info.message}
              name="message"
              style={{ width: '100%' }}
            ></TextField>
          </div>
          {add ? (
            <>
              <div>
                <h3> Select Employees</h3>
                <div>
                  {info.employeeIds?.map((item, index) => {
                    return (
                      <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                        <p>{item.name}</p>
                        <div
                          onClick={(e) => {
                            info.employeeIds.splice(index, 1);
                            console.log(index);
                            setInfo({ ...info, employeeIds: [...info.employeeIds] });
                          }}
                          style={{ cursor: 'pointer', display: 'flex', justifyContent: 'center', alignItems: 'center' }}
                        >
                          <img src={CloseIcon}></img>
                        </div>
                      </div>
                    );
                  })}
                </div>
                <Select
                  onChange={(e) => {
                    setInfo({
                      ...info,
                      employeeIds: [...info.employeeIds, { ...e.target.value, latitude: null, longitude: null }],
                    });
                  }}
                  name="employeeIds"
                  style={{ width: '100%' }}
                >
                  {employees
                    ?.filter((item1) => !info.employeeIds?.some((item2) => item2._id === item1._id))
                    ?.map((item) => {
                      return <MenuItem value={item}>{item.name}</MenuItem>;
                    })}
                </Select>
              </div>
              <div>
                <h3>Description</h3>
                <TextField
                  multiline={true}
                  rows={5}
                  onChange={inputhandler}
                  value={info.description}
                  name="description"
                  style={{ width: '100%' }}
                ></TextField>
              </div>
            </>
          ) : null}
        </DialogContent>

        <DialogActions>
          <Button style={{ color: '#D9B455' }} disabled={isDisabeld} onClick={() => addData()}>
            Add
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
