import { Helmet } from 'react-helmet-async';
import { useEffect, useState, useCallback } from 'react';
// @mui
import {
  Stack,
  Container,
  Typography,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  TextField,
  DialogTitle,
  Stepper,
  Step,
  StepLabel,
  Select,
  MenuItem,
  Box,
} from '@mui/material';

import { axiosApi } from 'src/service/apiEnviornment';
import Button from '@mui/material/Button';
import DeleteIcon from '../assets/trash.png';
import Iconify from 'src/components/iconify/Iconify';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { GoogleMap, useJsApiLoader, Marker, DirectionsRenderer } from '@react-google-maps/api';
import image from '../assets/pin.png';
import LocationImage from '../assets/home.png';

// import truck from "../assets/fire_truck.png"

import moment from 'moment';
// components

import Scrollbar from '../components/scrollbar';
// sections
import { UserListHead } from '../sections/@dashboard/user';
// mock
import { useParams } from 'react-router-dom';
import { useAuth } from 'src/hooks/useAuth';

import PinLocation from 'src/components/maps/PinLocation';
import GooglePlacesAutocomplete from 'react-google-places-autocomplete';
import CloseIcon from '../assets/close.png';

// ----------------------------------------------------------------------

const TABLE_HEAD = [
  { id: 'reqForEmergency', label: 'Emergency Occur', alignRight: false },
  // { id: 'lastName', label: 'Last Name', alignRight: false },

  { id: 'action', label: 'Action', alignRight: false },
];

// ----------------------------------------------------------------------

const steps = ['Assignment', 'Reached', 'Completed'];

export default function TaskDetails() {
  const { id } = useParams();
  const [status, setStatus] = useState();
  const { user } = useAuth();
  const [warning, setWarning] = useState(false);
  // console.log('status', status);

  const { isLoaded } = useJsApiLoader({
    id: 'google-map-script',
    googleMapsApiKey: 'AIzaSyAN_6e4jAOJNXNpYDjK5VrOaHLFadULobA',
  });
  const [map, setMap] = useState(null);

  const onUnmount = useCallback(function callback(map) {
    setMap(null);
  }, []);
  const [info, setInfo] = useState({
    firstName: '',
    lastName: '',
    cnic: '',
    phone: '',
    password: '',
    email: '',
    role: 'Driver',
  });
  const [directions, setDirections] = useState(null);
  const [data, setData] = useState();
  const [loader, setLoader] = useState(false);
  const [isDisabeld, setIsDisabeld] = useState(false);
  const [add, setAdd] = useState(false);
  const [activeStepNo, setActiveStepNo] = useState(1);
  const [drivers, setDrivers] = useState();
  const [trucks, setTrucks] = useState();
  const [driverId, setDriverId] = useState();
  const [truckId, setTruckId] = useState();
  const [note, setNote] = useState();
  const [location, setLocation] = useState({ lat: null, lng: null });

  const inputhandler = (event) => {
    const name = event.target.name;
    const value = event.target.value;
    setInfo({ ...info, [name]: value });
  };
  const handleFailure = (msg) => {
    toast.error(msg, {
      position: toast.POSITION.TOP_RIGHT,
      autoClose: 3000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
  };
  const handleSuccess = (msg) => {
    toast.success(msg, {
      position: toast.POSITION.TOP_RIGHT,
      autoClose: 3000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
  };

  const handleWarningModal = () => {
    setWarning(false);
  };

  function checkStep(res) {
    if (res.completed) {
      return 4;
    } else if (res.start) {
      return 3;
    } else if (res.reached) {
      return 2;
    } else {
      return 1;
    }
  }
  function checkStatus(res) {
    // console.log('user', user._id);
    if (res.employeeIds.length > 0) {
      res.employeeIds?.map((item) => {
        if (item._id == user._id) {
          if (item.completed) {
            setStatus('completed');
          }
          //    else if (item.start) {
          //     setStatus('started');
          //   }
          else if (item.reached) {
            setStatus('reached');
          }
          //    else {
          //     setStatus('assignment');
          //   }
        }
      });
    }
  }
  const getData = async () => {
    await axiosApi('GET', `/task/get-task-by-id/${id}`)
      .then((res) => {
        console.log("checking", res.data)
        checkStep(res.data);
        checkStatus(res.data);
        setData(res.data);
        const job = res.data.jobId
        setInfo({ ...res.data, ...job })
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const updateStatus = async (value) => {
    await axiosApi('PUT', `/task/add-tracking-in-task/${id}/${user._id}/${value}`)
      .then((res) => {
        handleSuccess('Status updated successfully');
        setStatus(value);
      })
      .catch((err) => {
        handleFailure('Error in updating status');
        console.log(err);
      });
  };
  const finish = async () => {
    await axiosApi('PUT', `/task/finish-task-by-id/${id}`, { completed: true })
      .then((res) => {
        handleSuccess('Status updated successfully');
        getData()
      })
      .catch((err) => {
        handleFailure('Error in updating status');
        console.log(err);
      });
  };


  const onSubmitNote = async () => {
    if (note) {
      await axiosApi('PUT', `/task/add-note/${id}`, { note })
        .then((res) => {
          // console.log(res);
          handleSuccess('Note added successfully');
          // getData();
        })
        .catch((err) => {
          handleFailure('Error in adding note');
          console.log(err);
        });
    }
  };

  const calculateDirections = () => {
    const directionsService = new window.google.maps.DirectionsService();

    directionsService.route(
      {
        origin: { lat: Number(data?.location?.latitude), lng: Number(data?.location?.longitude) },
        destination: { lat: Number(data?.driverLocation?.latitude), lng: Number(data?.driverLocation?.longitude) },
        travelMode: window.google.maps.TravelMode.DRIVING,
      },
      (result, status) => {
        if (status === window.google.maps.DirectionsStatus.OK) {
          setDirections(result);
        } else {
          console.error('Error fetching directions:', result);
        }
      }
    );
  };

  async function start() {
    // getlocation()
    let latitude = null;
    let longitude = null;
    navigator.geolocation.getCurrentPosition(
      async (position) => {
        // console.log('pos', position.cords);
        if (position.coords.latitude) {
          latitude = position.coords.latitude;
          longitude = position.coords.longitude;
          // console.log(latitude, longitude);
          setLocation({ lat: latitude, lng: longitude });
          await axiosApi('PUT', `/task/add-employee-location-in-task/${id}`, {
            latitude: latitude,
            longitude: longitude,
          })
            .then((res) => {
              // console.log(res);
              getData();
            })
            .catch((err) => {
              console.log(err);
            });
        } else {
          setWarning(true);
        }
      },
      (error) => {
        setWarning(true);
        console.log('Error getting your location: ' + error.message);
      }
    );
  }

  useEffect(() => {
    // console.log('executed');
    if (user.role != 'admin') {
      const interval = setInterval(() => {
        start();
        getData();
      }, 6000);

      return () => clearInterval(interval);
    }
  }, []);

  useEffect(() => {
    setLoader(true);
    getEmployees()
    getData();
    setLoader(false);
  }, []);

  function convertDateFormat(inputDateString) {
    const originalDate = new Date(inputDateString);

    originalDate.setHours(originalDate.getHours() + 8);

    const newDateString = originalDate.toISOString().slice(0, 16);

    return newDateString;
  }

  const [updateTimeModal, setUpdateTimeModal] = useState();
  const [trackingStatus, setTrackingStatus] = useState({ reached: null, completed: null, empId: null });

  const handleUpdateTimeModal = () => {
    setTrackingStatus({ reached: '', completed: '' });
  };
  const [reachedAt, setReachedAt] = useState(new Date());
  const [completedAt, setCompletedAt] = useState(new Date());

  const onUpdateTrackingStatus = async () => {
    const body = { reached: new Date(trackingStatus.reached), completed: new Date(trackingStatus.completed) };
    await axiosApi('PUT', `/task/add-tracking-in-task-from-admin/${id}/${trackingStatus.empId}`, body)
      .then((res) => {
        // console.log(res);
        setTrackingStatus({ reached: '', completed: '' });
        handleSuccess('Status updated successfully');
        getData();
      })
      .catch((err) => {
        handleFailure('Error in updating status');
        console.log(err);
      });
  };

  function getTimeDifference(startDate, endDate) {
    // Calculate the time difference in milliseconds
    const timeDiff = new Date(endDate) - new Date(startDate);

    // Convert milliseconds to hours and minutes
    const hours = Math.floor(timeDiff / 3600000); // 1 hour = 3600000 milliseconds
    const minutes = Math.floor((timeDiff % 3600000) / 60000); // 1 minute = 60000 milliseconds

    // Format the result as "hour:minute"
    const formattedTime = `${String(hours).padStart(2, '0')}:${String(minutes).padStart(2, '0')}`;
    return formattedTime;
  }
  // edit modal
  console.log("test", info)
  const [edit, setEdit] = useState(false);
  const handleClose = () => {
    setEdit(false);

  };
  const [employees, setEmployees] = useState([]);
  const getEmployees = async () => {
    setLoader(true);
    await axiosApi('GET', `/employee/get-all-employee`)
      .then((res) => {
        console.log(res.data);
        setEmployees(res.data);
      })
      .catch((err) => {
        console.log(err);
      });
    setLoader(false);
  };
  const addData = async () => {

    setIsDisabeld(true);
    await axiosApi("PUT", `/inquiry/update-inquiry-by-id/${info.jobId._id}`, info)
      .then((res) => {
        console.log(res)
        // handleSuccess("Lead added in jobs")
        // getData()


      })
      .catch((err) => {
        handleFailure("Error in editing Scheduler")
        console.log(err);
      });
    await axiosApi("PUT", `/task/update-task-by-id/${id}`, { ...info, _id: id, id, jobId: info.jobId._id })
      .then((res) => {
        // console.log(res)
        handleSuccess("Scheduler updated successfully")
        // getData()
      })
      .catch((err) => {
        handleFailure("Error in editing Scheduler")
        console.log(err);
      });
    handleClose();
    setIsDisabeld(false);
  };


  return (
    <>
      <Helmet>
        <title> Tracking </title>
      </Helmet>
      {user?.role == 'employee' ? (
        <Container>
          <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
            <Typography style={{ color: "#D9B455" }} variant="h4" gutterBottom>
              Employee Tracking
            </Typography>
            <Box style={{ display: 'flex' }}>
              <h3 style={{ marginRight: '20px' }}>Status : </h3>

              <Select
                value={status}
                onChange={(e) => {
                  updateStatus(e.target.value);
                }}
                style={{ width: '150px' }}
              >
                <MenuItem value="reached">Reached</MenuItem>
                {/* <MenuItem value="Started">Started</MenuItem> */}
                <MenuItem value="completed">Completed</MenuItem>
              </Select>
            </Box>
          </Stack>
        </Container>
      ) : (
        <Container>
          <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
            <div>
              <Typography style={{ color: "#D9B455" }} variant="h4" gutterBottom>
                Scheduler Tracking
              </Typography>
            </div>
            <div style={{display:"flex",flexDirection:"row",justifyContent:"space-between",alignItems:"center"}}>
              <Button
                style={{ backgroundColor: "#D9B455",marginRight:"20px" }}
                onClick={() => {
                  setEdit(true);
                }}
                variant="contained"
              >
                Edit
              </Button>
              {data?.completed == "false" ?
                <Button
                  style={{ backgroundColor: "#D9B455" }}
                  onClick={() => {
                    finish();
                  }}
                  variant="contained"
                >
                  Completed
                </Button>
                :<div> <p style={{ color: "#D9B455" }}>Completed</p></div>}
            </div>

          </Stack>
        </Container >
      )
      }

      <Container>
        <Stack direction="row" alignItems="center" justifyContent="space-between" mt={2} mb={2}>
          <Typography variant="h4" gutterBottom>
            Location
          </Typography>
        </Stack>
        {!loader ? (
          <div style={{ width: '100%' }}>
            {isLoaded ? (
              <GoogleMap
                mapContainerStyle={{ width: '100%', height: '500px' }}
                center={{ lat: Number(data?.latitude), lng: Number(data?.longitude) }}
                zoom={12}
                onUnmount={onUnmount}
              >
                <Marker
                  position={{ lat: Number(data?.latitude), lng: Number(data?.longitude) }}
                  icon={{
                    url: LocationImage,
                  }}
                />
                {/* {directions && <DirectionsRenderer suppressMarkers={true} options={{
                                directions: directions,
                                preserveViewport: true,
                                suppressMarkers: true
                            }} />} */}

                {user?.role == 'admin' ? (
                  data?.employeeIds?.map((item) => {
                    if (item?.latitude) {
                      return (
                        <Marker
                          position={{ lat: Number(item?.latitude), lng: Number(item?.longitude) }}
                          icon={{
                            url: image,
                          }}
                        />
                      );
                    }
                  })
                ) : location.lat ? (
                  <Marker
                    position={{ lat: Number(location.lat), lng: Number(location.lng) }}
                    icon={{
                      url: image,
                    }}
                  />
                ) : null}
              </GoogleMap>
            ) : null}
          </div>
        ) : (
          <CircularProgress style={{ color: "#D9B455" }} />
        )}
      </Container>

      <Container>
        <Stack direction="row" alignItems="center" justifyContent="space-between" mt={2} mb={2}>
          <Typography variant="h4" gutterBottom>
            Customer Details
          </Typography>
        </Stack>
        {!loader ? (
          <Box style={{ border: '2px #D9B455 solid', borderRadius: '10px', padding: '0px 20px', marginBottom: '10px' }}>
            <div style={{ display: 'flex', width: '100%' }}>
              <p style={{ width: '50%' }}>Name</p>
              <p style={{ width: '50%' }}>{data?.jobId?.name}</p>
            </div>
            <div style={{ display: 'flex', width: '100%' }}>
              <p style={{ width: '50%' }}>Phone</p>
              <p style={{ width: '50%' }}>{data?.jobId?.phoneNumber}</p>
            </div>
            <div style={{ display: 'flex', width: '100%' }}>
              <p style={{ width: '50%' }}>Email</p>
              <p style={{ width: '50%' }}>{data?.jobId?.email}</p>
            </div>
            <div style={{ display: 'flex', width: '100%' }}>
              <p style={{ width: '50%' }}>Message</p>
              <p style={{ width: '50%' }}>{data?.jobId?.message}</p>
            </div>

            <div style={{ display: 'flex', width: '100%' }}>
              <p style={{ width: '50%' }}>Address</p>
              <p style={{ width: '50%' }}>{data?.jobId?.address}</p>
            </div>
            <div style={{ display: 'flex', width: '100%' }}>
              <p style={{ width: '50%' }}>Job Work Order</p>
              <p style={{ width: '50%' }}>{data?.description}</p>
            </div>
            <div style={{ display: 'flex', width: '100%' }}>
              <p style={{ width: '50%' }}>Job Start Time</p>
              <p style={{ width: '50%' }}>{moment(data?.jobId?.date).format("YYYY-MM-DD")} At {data?.jobId?.startTime}</p>
            </div>
            <div style={{ display: 'flex', width: '100%' }}>
              <p style={{ width: '50%' }}>Job End Time</p>
              <p style={{ width: '50%' }}>{moment(data?.jobId?.date).format("YYYY-MM-DD")} At {data?.jobId?.endTime}</p>
            </div>
          </Box>
        ) : (
          <CircularProgress style={{ color: "#D9B455" }} />
        )}
      </Container>

      <Container>
        <Stack direction="row" alignItems="center" justifyContent="space-between" mt={2} mb={2}>
          <Typography variant="h4" gutterBottom>
            Employee Details
          </Typography>
        </Stack>
        {!loader ? (
          data?.employeeIds?.map((item) => {
            return (
              <Box>
                {user.role == 'admin' ? (
                  <Box
                    sx={{
                      width: '100%',
                      marginTop: '20px',
                      marginBottom: '20px',
                      display: 'flex',
                      justifyContent: 'space-between',
                    }}
                  >
                    <Stepper sx={{ width: '80%' }} activeStep={checkStep(item)} alternativeLabel>
                      {steps?.map((label) => (
                        <Step
                          sx={{
                            '& .MuiStepLabel-root .Mui-completed': {
                              color: '#D9B455', // circle color (COMPLETED)
                            }
                            ,
                            '& .MuiStepLabel-root .Mui-active': {
                              color: '#D9B455', // circle color (ACTIVE)
                            },
                          }}
                          key={label}>
                          <StepLabel>{label}</StepLabel>
                        </Step>
                      ))}
                    </Stepper>
                    <Button
                      style={{ backgroundColor: "#D9B455" }}
                      variant="contained"
                      onClick={() => {
                        // setUpdateTimeModal(true);
                        setTrackingStatus({
                          reached: item.reached ? convertDateFormat(item.reached) : null,
                          completed: item.completed ? convertDateFormat(item.completed) : null,
                          empId: item._id,
                        });
                      }}
                    >
                      Update Time
                    </Button>
                  </Box>
                ) : null}

                <Box
                  style={{ border: '2px #D9B455 solid', borderRadius: '10px', padding: '0px 20px', marginBottom: '10px' }}
                >
                  <div style={{ display: 'flex', width: '100%' }}>
                    <p style={{ width: '50%' }}>Name</p>
                    <p style={{ width: '50%' }}>{item.name}</p>
                  </div>
                  <div style={{ display: 'flex', width: '100%' }}>
                    <p style={{ width: '50%' }}>Phone</p>
                    <p style={{ width: '50%' }}>{item.phoneNumber}</p>
                  </div>
                  <div style={{ display: 'flex', width: '100%' }}>
                    <p style={{ width: '50%' }}>Email</p>
                    <p style={{ width: '50%' }}>{item.email}</p>
                  </div>
                  {user.role == 'admin' ? (

                    <div>
                      {(item.note) ?
                        <div style={{ display: 'flex', width: '100%' }}>
                          <p style={{ width: '50%' }}>Notes</p>
                          <p style={{ width: '50%' }}>{item.note}</p>
                        </div>
                        : null}
                      {(item.reached) ?

                        <div style={{ display: 'flex', width: '100%' }}>
                          <p style={{ width: '50%' }}>Reached</p>
                          <p style={{ width: '50%' }}>{moment(item.reached).format("YYYY-MM-DD")} At {moment(item.reached).format("HH:MM")}  </p>
                        </div>
                        : null}

                      {(item.completed) ?

                        <div style={{ display: 'flex', width: '100%' }}>
                          <p style={{ width: '50%' }}>Completed</p>
                          <p style={{ width: '50%' }}>{moment(item.completed).format("YYYY-MM-DD")} At {moment(item.completed).format("HH:MM")}  </p>
                        </div>
                        : null}
                      {(item.completed) ?

                        <div style={{ display: 'flex', width: '100%' }}>
                          <p style={{ width: '50%' }}>Time Spent</p>
                          <p style={{ width: '50%' }}>{getTimeDifference(item.reached, item.completed)}  </p>
                        </div>
                        : null}

                    </div>

                  ) : null}
                </Box>
              </Box>
            );
          })
        ) : (
          <CircularProgress style={{ color: "#D9B455" }} />
        )}
      </Container>
      {
        user.role === 'employee' && (
          <>
            <Container>
              <Typography variant="h4" mt={2} mb={2}>
                Add note
              </Typography>
              <TextField
                fullWidth
                onChange={(e) => setNote(e.target.value)}
                value={note}
                placeholder="Add note here"
                multiline
                rows={2}
                maxRows={4}
              />
            </Container>
            <Container>
              <Typography mt={2} mb={2}>
                <Button style={{ backgroundColor: "#D9B455" }} onClick={onSubmitNote} variant="contained">
                  Add Note
                </Button>
              </Typography>
            </Container>
          </>
        )
      }
      <ToastContainer />
      <Dialog open={warning} onClose={handleWarningModal}>
        <DialogTitle id="alert-dialog-title">Warning </DialogTitle>
        <DialogContent style={{ minWidth: '600px' }}>
          <div>
            <p>Allow access for location</p>
          </div>
          <DialogActions>
            <Button onClick={() => handleWarningModal()}>Close</Button>
          </DialogActions>
        </DialogContent>
      </Dialog>

      <Dialog open={trackingStatus.reached} onClose={handleUpdateTimeModal}>
        <DialogTitle sx={{ color: "#D9B455" }}>Change time </DialogTitle>
        <DialogContent style={{ minWidth: '600px' }}>
          <Box sx={{ mb: 2 }}>
            <Typography>Reached at :</Typography>
            <input
              type="datetime-local"
              onChange={(e) => setTrackingStatus({ ...trackingStatus, reached: e.target.value })}
              value={trackingStatus.reached}
            />
          </Box>
          <Box>
            <Typography>Completed at :</Typography>
            <input
              type="datetime-local"
              onChange={(e) => setTrackingStatus({ ...trackingStatus, completed: e.target.value })}
              value={trackingStatus.completed}
            />
          </Box>
          <DialogActions>
            <Button sx={{ backgroundColor: "#D9B455" }} variant="contained" onClick={() => onUpdateTrackingStatus()}>
              Update
            </Button>
          </DialogActions>
        </DialogContent>
      </Dialog>
      {/* edit modal */}
      <Dialog
        open={edit}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle style={{ color: '#D9B455' }} id="alert-dialog-title">
          {'Update Job'}
        </DialogTitle>

        <DialogContent style={{ minWidth: '600px' }}>
          <div>
            <h3>Name</h3>
            <TextField onChange={inputhandler} value={info.name} name="name" style={{ width: '100%' }}></TextField>
          </div>
          <div>
            <h3>email</h3>
            <TextField onChange={inputhandler} value={info.email} name="email" style={{ width: '100%' }}></TextField>
          </div>
          <div>
            <h3>Phone Number</h3>
            <TextField
              onChange={inputhandler}
              value={info.phoneNumber}
              name="phoneNumber"
              style={{ width: '100%' }}
            ></TextField>
          </div>
          <div>
            <h3>Type</h3>
            <TextField onChange={inputhandler} value={info.type} name="type" style={{ width: '100%' }}></TextField>
          </div>
          <div>
            <h3>Date</h3>
            <TextField
              type="date"
              onChange={inputhandler}
              value={moment(new Date(info.date)).format('YYYY-MM-DD')}
              name="date"
              style={{ width: '100%' }}
            ></TextField>
          </div>
          <div>
            <h3>Start Time</h3>
            <TextField
              type="time"
              onChange={inputhandler}
              value={info.startTime}
              name="startTime"
              style={{ width: '100%' }}
            ></TextField>
          </div>
          <div>
            <h3>End Time</h3>
            <TextField
              type="time"
              onChange={inputhandler}
              value={info.endTime}
              name="endTime"
              style={{ width: '100%' }}
            ></TextField>
          </div>
          <div>
            <h3>Address</h3>
            <TextField
              type="text"
              onChange={inputhandler}
              value={info.address}
              name="address"
              style={{ width: '100%' }}
            ></TextField>
          </div>
          <div>
            <h3>Nearest Point</h3>
            <div>
              <GooglePlacesAutocomplete
                apiKey="AIzaSyAN_6e4jAOJNXNpYDjK5VrOaHLFadULobA"
                autocompletionRequest={{}}
                selectProps={{
                  placeholder: 'Nearest Point',
                  name: 'nearestPoint',
                  onChange: inputhandler,
                }}
                onLoadFailed={(error) => {
                  console.error('Could not inject Google script', error);
                }}
              />
            </div>
          </div>
          <div>
            <h3>How did you hear about us?</h3>
            <TextField
              onChange={inputhandler}
              value={info.hearAboutUs}
              name="hearAboutUs"
              style={{ width: '100%' }}
            ></TextField>
          </div>
          <div>
            <h3>Message</h3>
            <TextField
              multiline={true}
              rows={5}
              onChange={inputhandler}
              value={info.message}
              name="message"
              style={{ width: '100%' }}
            ></TextField>
          </div>
          {/* {add ? (
            <> */}
          <div>
            <h3> Select Employees</h3>
            <div>
              {info.employeeIds?.map((item, index) => {
                return (
                  <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                    <p>{item.name}</p>
                    <div
                      onClick={(e) => {
                        info.employeeIds.splice(index, 1);
                        console.log(index);
                        setInfo({ ...info, employeeIds: [...info.employeeIds] });
                      }}
                      style={{ cursor: 'pointer', display: 'flex', justifyContent: 'center', alignItems: 'center' }}
                    >
                      <img src={CloseIcon}></img>
                    </div>
                  </div>
                );
              })}
            </div>
            <Select
              onChange={(e) => {
                setInfo({
                  ...info,
                  employeeIds: [...info.employeeIds, { ...e.target.value, latitude: null, longitude: null }],
                });
              }}
              name="employeeIds"
              style={{ width: '100%' }}
            >
              {employees
                ?.filter((item1) => !info.employeeIds?.some((item2) => item2._id === item1._id))
                ?.map((item) => {
                  return <MenuItem value={item}>{item.name}</MenuItem>;
                })}
            </Select>
          </div>
          <div>
            <h3>Description</h3>
            <TextField
              multiline={true}
              rows={5}
              onChange={inputhandler}
              value={info.description}
              name="description"
              style={{ width: '100%' }}
            ></TextField>
          </div>
          {/* </>
          ) : null} */}
        </DialogContent>

        <DialogActions>
          <Button style={{ color: '#D9B455' }} disabled={isDisabeld} onClick={() => addData()}>
            Update
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}
